<template>
    <div id="error">
        <!-- Preloader Start -->
        <div class="preloader">
            <div class="loading-container">
                <div class="loading"></div>
                <div id="loading-icon"><img
                        src="https://ingomulibrary.s3.us-east-1.amazonaws.com/images/Ingomu-Logo-White.png" alt="">
                </div>
            </div>
        </div>
        <!-- Preloader End -->
  
        <!-- Header Start -->
        <SiteHeader></SiteHeader>
        <!-- Header End -->
  
      <!-- Page Header Start -->
      <div class="page-header bg-section parallaxie">
          <!-- Page Header Box Start -->
          <div class="page-header-box">
              <div class="container-fluid">
                  <div class="row">
                      <div class="col-lg-12">
                          <!-- Page Header Content Start -->
                          <div class="page-header-content">
                              <h1 class="wow fadeInUp">Page <span>Not Found.</span></h1>
                              <nav class="wow fadeInUp" data-wow-delay="0.25s">
                                  <ol class="breadcrumb">
                                      <li class="breadcrumb-item"><router-link :to="{ name: 'home'}">home</router-link></li>
                                      <li class="breadcrumb-item active" aria-current="page">404 error page</li>
                                  </ol>
                              </nav>
                          </div>
                          <!-- Page Header Content End -->
                      </div>
                  </div>
              </div>
          </div>	
          <!-- Page Header Box End -->	
      </div>
      <!-- Page Header End -->
  
      <!-- error section start -->
      <div class="error-page">
          <div class="container">
              <div class="row">
                  <div class="col-lg-12">
                      <div class="error-page-image wow fadeInUp">
                          <img src="https://ingomulibrary.s3.us-east-1.amazonaws.com/images/404-error-img.png" alt="">
                      </div>
                      <div class="error-page-content">
                          <div class="section-title">
                              <h2 class="wow fadeInUp" data-wow-delay="0.25s">Oops!<span>Page Not Found</span></h2>
                          </div>
                          <div class="error-page-content-body">
                              <p class="wow fadeInUp" data-wow-delay="0.5s">The page you are looking for does not exist.</p>
                              <router-link :to="{ name: 'home'}" class="btn-default wow fadeInUp" data-wow-delay="0.75s">back to home</router-link>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      <!-- error section end -->
  
        <!-- Footer Start -->
        <SiteFooter></SiteFooter>
        <!-- Footer End -->
    </div>
  </template>
  <script>
  import SiteHeader from "@/components/InfineHeader";
  import SiteFooter from "@/components/InfineFooter";
  import $ from "jquery";
  import { slicknav } from '../mixins/slicknav';
  import { parrallaxie } from "../mixins/parallaxie";
  import { mapActions, mapGetters } from "vuex";
  import { reveal } from "../mixins/reveal";
  
  export default {
    name: "errorv4",
    metaInfo() {
		return {
			title: "404 Error Page | Ingomu App",
		}
	},
    components: {
        SiteHeader,
        SiteFooter
    },
    mounted() {
        var $window = $(window);
  
        // /* Preloader Effect */
        // $window.on('load', function () {
        //     $(".preloader").fadeOut(600);
        // });
  
        $(function () {
            /* Preloader Effect */
            setTimeout(function () {
                $(".preloader").fadeOut(600);
            }, 600);
  
            /* Animated Wow Js */
            new WOW().init();
  
            // Parallax
            parrallaxie()
  
            // Reveal
            reveal()
  
            /* Slick Menu JS */
            slicknav()
        })
    },
  }
  </script>
  <style scoped>
  .page-header {
    background: url(https://ingomulibrary.s3.us-east-1.amazonaws.com/images/page-not-found.png);
  }
  
  .post-featured-image figure a {
    cursor: pointer;
  }

  @media (max-width:480px) {
    .page-header {
        background: url(https://ingomulibrary.s3.us-east-1.amazonaws.com/images/page-not-found-mobile.png);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
    }
}
  </style>