<template>
    <div id="blog">
        <!-- Preloader Start -->
        <div class="preloader">
            <div class="loading-container">
                <div class="loading"></div>
                <div id="loading-icon"><img
                        src="https://ingomulibrary.s3.us-east-1.amazonaws.com/images/Ingomu-Logo-White.png" alt="">
                </div>
            </div>
        </div>
        <!-- Preloader End -->

        <!-- Header Start -->
        <SiteHeader></SiteHeader>
        <!-- Header End -->

        <!-- Page Header Start -->
        <div class="page-header bg-section parallaxie">
            <!-- Page Header Box Start -->
            <div class="page-header-box">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-lg-12">
                            <!-- Page Header Content Start -->
                            <div class="page-header-content">
                                <h1 class="wow fadeInUp">Terms &<br><span>Privacy</span></h1>
                                <nav class="wow fadeInUp" data-wow-delay="0.25s">
                                    <ol class="breadcrumb">
                                        <li class="breadcrumb-item"><router-link
                                                :to="{ name: 'home' }">home</router-link></li>
                                        <li class="breadcrumb-item active" aria-current="page">Terms & Privacy</li>
                                    </ol>
                                </nav>
                            </div>
                            <!-- Page Header Content End -->
                        </div>
                    </div>
                </div>
            </div>
            <!-- Page Header Box End -->
        </div>
        <!-- Page Header End -->

        <!-- Page Single Post Start -->
        <div class="page-single-post">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <!-- Terms Content Start -->
                        <div class="post-content">
                            <!-- Terms Start -->
                            <div class="post-entry">
                                <div>
                                    <div id="opt1">
                                        <h1 style="font-weight: bold; font-size: 14pt; margin: 0">
                                            <span class="title-font">Legal Center</span>
                                        </h1>
                                        <br />
                                        Welcome to the Ingomu Learning Legal Center. The information
                                        provided here is for Ingomu Learning users who have questions
                                        about our policies, terms, and compliance. We appreciate the
                                        trust you place in us, and should you have any questions, we
                                        are happy to speak with you.
                                        <br />
                                        <br />
                                        <span class="title-font">CONTACT US AT:</span><br />
                                        Ingomu Learning LLC<br />
                                        <a href="mailto:Contact@Ingomu.com">Contact@Ingomu.com</a><br />
                                        Phone: 720.634.5283
                                        <br />
                                        <br />
                                        <span class="title-font">Important, read carefully: Your use of and access of
                                            the
                                            website, app and products and services (collectively, the
                                            "Ingomu Services") of Ingomu Learning LLC ("Ingomu") is
                                            conditioned upon your compliance and acceptance of these
                                            terms and conditions (“Terms”). You must accept these Terms
                                            to create an Ingomu account and to access or use the Ingomu
                                            Services. If you do not have an account, you accept these
                                            Terms by using any part of the Ingomu Services. If you do
                                            not accept these Terms, do not create an account or use the
                                            Ingomu Services. <br />
                                            <br />
                                            Your use of the Ingomu App, our live virtual group coaching
                                            and on-demand coaching services, or any of our paid services
                                            are subject to additional terms and conditions, which are
                                            incorporated in these Terms.
                                        </span>
                                        <div id="terms"></div>
                                        <br />
                                        <br />
                                        <span class="title-font" style="color: #3b5998">TERMS OF USE
                                        </span>
                                        <a href="#top" style="float: right"><span class="title-font">Back to
                                                Top</span></a><br />
                                        Last Updated, September 14, 2021
                                        <br />
                                        <br />
                                        These Terms of Use constitute a legally binding agreement made
                                        between you, whether personally or on behalf of an entity
                                        (“you”) and Ingomu Learning LLC (“we,” “us” or “our”),
                                        concerning your access to and use of the Ingomu Services. You
                                        agree that by accessing the Ingomu Services, you have read,
                                        understood, and agree to be bound by all of these Terms of
                                        Use. If you do not agree with all of these Terms of Use, then
                                        you are expressly prohibited from using the Ingomu Services
                                        and you must discontinue use immediately.
                                        <br />
                                        <br />
                                        Supplemental terms and conditions or documents that may be
                                        posted on the Ingomu Services from time to time are hereby
                                        expressly incorporated herein by reference. We reserve the
                                        right, in our sole discretion, to make changes or
                                        modifications to these Terms of Use at any time and for any
                                        reason. We will alert you about any changes by updating the
                                        “Last updated” date of these Terms of Use, and you waive any
                                        right to receive specific notice of each such change. It is
                                        your responsibility to periodically review these Terms of Use
                                        to stay informed of updates. You will be subject to and will
                                        be deemed to have been made aware of and to have accepted, the
                                        changes in any revised Terms of Use by your continued use of
                                        the Site after the date such revised Terms of Use are posted.
                                        <br />
                                        <br />
                                        The information provided on the Ingomu Services is not
                                        intended for distribution to or use by any person or entity in
                                        any jurisdiction or country where such distribution or use
                                        would be contrary to law or regulation, or which would subject
                                        us to any registration requirement within such jurisdiction or
                                        country. Accordingly, those persons who choose to access the
                                        Ingomu Services from other locations do so on their own
                                        initiative and are solely responsible for compliance with
                                        local laws, if and to the extent local laws are applicable.
                                        <br />
                                        <br />
                                        The Ingomu Services are intended for users who are at least 18
                                        years old. Persons under the age of 18 are not permitted to
                                        use or register for the Site. Individuals under the age of 18,
                                        or applicable age of majority, may utilize the service only
                                        with the involvement of a parent or legal guardian, under such
                                        person's account and otherwise subject to these Terms of Use.
                                        <br />
                                        <br />
                                        Ingomu Learning offers a diverse set of coaches and topics.
                                        Some topics may touch on issues that may cause discomfort. At
                                        Ingomu Learning we believe that discomfort is a catalyst for
                                        growth. It is your responsibility to determine if a subject
                                        matter is uncomfortable to you, and if you should or should
                                        not join such sessions.
                                        <br />
                                        <br />
                                        1. INTELLECTUAL PROPERTY RIGHTS
                                        <br />
                                        <br />
                                        Unless otherwise indicated, the Ingomu Services are our
                                        proprietary property and all source code, databases,
                                        functionality, software, website designs, audio, video, text,
                                        photographs, and graphics on the Site (collectively, the
                                        “Content”) and the trademarks, service marks, and logos
                                        contained therein (the “Marks”) are owned or controlled by us
                                        or licensed to us, and are protected by copyright and
                                        trademark laws and various other intellectual property rights
                                        and unfair competition laws of the United States, foreign
                                        jurisdictions, and international conventions. The Content and
                                        the Marks are provided on the Site “AS IS” for your
                                        information and personal use only. Except as expressly
                                        provided in these Terms of Use, no part of the Site and no
                                        Content or Marks may be copied, reproduced, aggregated,
                                        republished, uploaded, posted, publicly displayed, encoded,
                                        translated, transmitted, distributed, sold, licensed, or
                                        otherwise exploited for any commercial purpose whatsoever,
                                        without our express prior written permission.
                                        <br />
                                        <br />
                                        Any Content posted by Coaches remains the sole property of the
                                        Coaches and is protected by copyright and trademark laws and
                                        various other intellectual property rights and unfair
                                        competition laws of the United States, foreign jurisdictions,
                                        and international conventions. The Content is provided in the
                                        Coach’s Learning Community “AS IS” for your information and
                                        personal use only. Except as expressly provided in these Terms
                                        of Use, and/or by the Coach, no part of the Coach’s Learning
                                        Community may be copied, reproduced, aggregated, republished,
                                        uploaded, posted, publicly displayed, encoded, translated,
                                        transmitted, distributed, sold, licensed, or otherwise
                                        exploited for any commercial purpose whatsoever, without our
                                        express prior written permission.
                                        <br />
                                        <br />
                                        2. USER REPRESENTATIONS
                                        <br />
                                        <br />
                                        By using the Ingomu Services, you represent and warrant that:
                                        (1) all registration information you submit will be true,
                                        accurate, current, and complete; (2) you will maintain the
                                        accuracy of such information and promptly update such
                                        registration information as necessary; (3) you have the legal
                                        capacity and you agree to comply with these Terms of Use; (4)
                                        you are not a minor in the jurisdiction in which you reside;
                                        (5) you will not access the Ingomu Services through automated
                                        or non-human means, whether through a bot, script or
                                        otherwise; (6) you will not use the Ingomu Services for any
                                        illegal or unauthorized purpose; and (7) your use of the
                                        Ingomu Services will not violate any applicable law or
                                        regulation.
                                        <br />
                                        <br />
                                        If you provide any information that is untrue, inaccurate, not
                                        current, or incomplete, we have the right to suspend or
                                        terminate your account and refuse any and all current or
                                        future use of the Ingomu Services (or any portion thereof).
                                        Should your account be suspended or terminated because you
                                        provided untrue, inaccurate, not current, or incomplete
                                        information, then no partial or full refund will be provided
                                        for any services purchased.
                                        <br />
                                        <br />
                                        3. USER REGISTRATION
                                        <br />
                                        <br />
                                        To use the Ingomu Services (App) you are required to download
                                        and register for the Ingomu Services via the Google Play
                                        Store. You agree to keep your password confidential, and you
                                        will be fully responsible for all use of your account and
                                        password and all activities that occur under your account. We
                                        reserve the right to remove, reclaim, or change a username you
                                        select if we determine, in our sole discretion, that such
                                        username is inappropriate, obscene, or otherwise
                                        objectionable. Coaches and Users may report objectionable
                                        usernames at
                                        <a href="mailto:Contact@Ingomu.com">Contact@Ingomu.com</a>. We
                                        also reserve the right to terminate your account should we
                                        discover that your account is being shared by multiple users
                                        to access the Ingomu Services. Ingomu Learning LLC cannot and
                                        will not be held liable for any loss, damage or other
                                        liability arising from your failure to comply with this
                                        Section or any other form of unauthorized access to our
                                        account.
                                        <br />
                                        <br />
                                        4. FEES AND PAYMENT
                                        <br />
                                        <br />
                                        Payments for the Ingomu Services App are processed by the
                                        Google Play store and are subject to their terms.
                                        <br />
                                        <br />
                                        You may be required to purchase or pay a fee to access some of
                                        our services. You agree to provide current, complete, and
                                        accurate purchase and account information for all purchases
                                        made via the Ingomu Services. You agree to pay all charges or
                                        fees at the prices then in effect for your purchases, and you
                                        authorize Google Play to charge your chosen payment provider
                                        for any such amounts upon making your purchase. If your
                                        purchase is subject to recurring charges, then you consent to
                                        Google Play charging your payment method on a recurring basis
                                        without requiring your prior approval for each recurring
                                        charge, until you notify us of your cancellation. We reserve
                                        the right to correct any errors or mistakes in pricing, even
                                        if we have already requested or received payment. We also
                                        reserve the right to refuse any order placed through the
                                        Ingomu Services.
                                        <br />
                                        <br />
                                        You can cancel your Ingomu Subscription Plan by going to the
                                        Google Play Store App on your device. Tap your profile avatar,
                                        then select Payments and subscriptions. Select Ingomu and
                                        follow the instructions to cancel your plan. Make sure you
                                        cancel your plan no less than 24 hours prior to your renewal
                                        date to ensure you do not get billed by the Google Play Store.
                                        <br />
                                        <br />
                                        5. CANCELLATION OR CHANGING PLANS
                                        <br />
                                        <br />
                                        You can cancel your Ingomu Subscription Plan by going to the
                                        Google Play Store App on your device. Tap your profile avatar,
                                        then select Payments and subscriptions. Select Ingomu and
                                        follow the instructions to cancel your plan. Make sure you
                                        cancel your plan no less than 24 hours prior to your renewal
                                        date to ensure you do not get billed by the Google Play Store.
                                        <br />
                                        <br />
                                        You can change your Ingomu Subscription Plan by going to the
                                        Google Play Store App on your device. Tap your profile avatar,
                                        then select Payments and subscriptions. Select Ingomu to
                                        update and follow the instructions to update your plan.
                                        <br />
                                        <br />
                                        6. PROHIBITED ACTIVITIES
                                        <br />
                                        <br />
                                        You may not access or use the Ingomu Services for any purpose
                                        other than that for which we make the Ingomu Services
                                        available. The Ingomu Services may not be used in connection
                                        with any commercial endeavors except those that are
                                        specifically endorsed or approved by us.
                                        <br />
                                        <br />
                                        As a user of the Ingomu Services, you agree not to:
                                        <br />
                                        <br />
                                        1. Systematically retrieve data or other content from the
                                        Ingomu Services to create or compile, directly or indirectly,
                                        a collection, compilation, database, or directory without
                                        written permission from us.
                                        <br />
                                        2. Make any unauthorized use of the Ingomu Services, including
                                        collecting usernames and/or email addresses of users by
                                        electronic or other means for the purpose of sending
                                        unsolicited email, or creating user accounts by automated
                                        means or under false pretenses.
                                        <br />
                                        3. Use a buying agent or purchasing agent to make purchases on
                                        the Ingomu Services.
                                        <br />
                                        4. Use the Ingomu Services to advertise or offer to sell goods
                                        and services.
                                        <br />
                                        5. Circumvent, disable, or otherwise interfere with
                                        security-related features of the Ingomu Services, including
                                        features that prevent or restrict the use or copying of any
                                        Content or enforce limitations on the use of the Ingomu
                                        Services and/or the Content contained therein.
                                        <br />
                                        6. Engage in unauthorized framing of or linking to the Ingomu
                                        Services.
                                        <br />
                                        7. Trick, defraud, or mislead us and other users, especially
                                        in any attempt to learn sensitive account information such as
                                        user passwords.
                                        <br />
                                        8. Make improper use of our support services or submit false
                                        reports of abuse or misconduct.
                                        <br />
                                        9. Engage in any automated use of the system, such as using
                                        scripts to send comments or messages, or using any data
                                        mining, robots, or similar data gathering and extraction
                                        tools.
                                        <br />
                                        10. Interfere with, disrupt, or create an undue burden on the
                                        Ingomu Services or the networks or services connected to the
                                        Ingomu Services.
                                        <br />
                                        11. Attempt to impersonate another user or person or use the
                                        username of another user.
                                        <br />
                                        12. Sell or otherwise transfer your profile.
                                        <br />
                                        13. Use any information obtained from the Ingomu Services in
                                        order to harass, abuse, or harm another person.
                                        <br />
                                        14. Use the Site as part of any effort to compete with us or
                                        otherwise use the Ingomu Services and/or the Content for any
                                        revenue-generating endeavor or commercial enterprise.
                                        <br />
                                        15. Decipher, decompile, disassemble, or reverse engineer any
                                        of the software comprising or in any way making up a part of
                                        the Ingomu Services.
                                        <br />
                                        16. Attempt to bypass any measures of the Ingomu Services
                                        designed to prevent or restrict access to the Ingomu Services,
                                        or any portion of the Ingomu Services.
                                        <br />
                                        17. Harass, annoy, intimidate, or threaten any of our
                                        employees, coaches or agents engaged in providing any portion
                                        of the Ingomu Services to you.
                                        <br />
                                        18. Delete the copyright or other proprietary rights notice
                                        from any Content.
                                        <br />
                                        19. Copy or adapt the Ingomu Services’ software.
                                        <br />
                                        20. Upload or transmit (or attempt to upload or to transmit)
                                        viruses, Trojan horses, or other material, including excessive
                                        use of capital letters and spamming (continuous posting of
                                        repetitive text), that interferes with any party’s
                                        uninterrupted use and enjoyment of the Ingomu Services or
                                        modifies, impairs, disrupts, alters, or interferes with the
                                        use, features, functions, operation, or maintenance of the
                                        Site.
                                        <br />
                                        21. Upload or transmit (or attempt to upload or to transmit)
                                        any material that acts as a passive or active information
                                        collection or transmission mechanism, including without
                                        limitation, clear graphics interchange formats (“gifs”), 1×1
                                        pixels, web bugs, cookies, or other similar devices (sometimes
                                        referred to as “spyware” or “passive collection mechanisms” or
                                        “pcms”).
                                        <br />
                                        22. Except as may be the result of standard search engine or
                                        Internet browser usage, use, launch, develop, or distribute
                                        any automated system, including without limitation, any
                                        spider, robot, cheat utility, scraper, or offline reader that
                                        accesses the Ingomu Services, or using or launching any
                                        unauthorized script or other software.
                                        <br />
                                        23. Disparage, tarnish, or otherwise harm, in our opinion, us
                                        and/or the Ingomu Services, Coaches and Learners.
                                        <br />
                                        24. Use the Ingomu Services in a manner inconsistent with any
                                        applicable laws or regulations.
                                        <br />
                                        <br />
                                        7. USER GENERATED CONTRIBUTIONS
                                        <br />
                                        <br />
                                        Ingomu Services may invite you to chat, contribute to, or
                                        participate in message boards, online forums, and other
                                        functionality, and may provide you with the opportunity to
                                        create, submit, post, display, transmit, perform, publish,
                                        distribute, or broadcast content and materials to us or on the
                                        Ingomu Services, including but not limited to text, writings,
                                        video, audio, photographs, graphics, comments, suggestions, or
                                        personal information or other material (collectively,
                                        “Contributions”). Contributions are viewable by other users.
                                        As such, any Contributions you transmit may be treated as
                                        non-confidential and non-proprietary. When you create or make
                                        available any Contributions, you thereby represent and warrant
                                        that:
                                        <br />
                                        <br />
                                        The creation, distribution, transmission, public display, or
                                        performance, and the accessing, downloading, or copying of
                                        your Contributions do not and will not infringe the
                                        proprietary rights, including but not limited to the
                                        copyright, patent, trademark, trade secret, or moral rights of
                                        any third party.
                                        <br />
                                        <br />
                                        1. You are the creator and owner of or have the necessary
                                        licenses, rights, consents, releases, and permissions to use
                                        and to authorize us, the Ingomu Services, and other users of
                                        the Ingomu Services to use your Contributions in any manner
                                        contemplated by the Ingomu Services and these Terms of Use.
                                        <br />
                                        2. You have the written consent, release, and/or permission of
                                        each and every identifiable individual person in your
                                        Contributions to use the name or likeness of each and every
                                        such identifiable individual person to enable inclusion and
                                        use of your Contributions in any manner contemplated by the
                                        Ingomu Services and these Terms of Use.
                                        <br />
                                        3. Your Contributions are not false, inaccurate, or
                                        misleading.
                                        <br />
                                        4. Your Contributions are not unsolicited or unauthorized
                                        advertising, promotional materials, pyramid schemes, chain
                                        letters, spam, mass mailings, or other forms of solicitation.
                                        <br />
                                        5. Your Contributions are not obscene, lewd, lascivious,
                                        filthy, violent, harassing, libelous, slanderous, or otherwise
                                        objectionable (as determined by us).
                                        <br />
                                        6. Your Contributions do not ridicule, mock, disparage,
                                        intimidate, or abuse anyone.
                                        <br />
                                        7. Your Contributions do not advocate the violent overthrow of
                                        any government or incite, encourage, or threaten physical harm
                                        against another.
                                        <br />
                                        8. Your Contributions do not violate any applicable law,
                                        regulation, or rule.
                                        <br />
                                        9. Your Contributions do not violate the privacy or publicity
                                        rights of any third party.
                                        <br />
                                        10. Your Contributions do not contain any material that
                                        solicits personal information from anyone under the age of 18
                                        or exploits people under the age of 18 in a sexual or violent
                                        manner.
                                        <br />
                                        11. Your Contributions do not violate any federal or state law
                                        concerning child pornography, or otherwise intended to protect
                                        the health or well-being of minors.
                                        <br />
                                        12. Your Contributions do not include any offensive comments
                                        that are connected to race, national origin, gender, sexual
                                        preference, sexual identity, or physical handicap.
                                        <br />
                                        13. Your Contributions do not otherwise violate, or link to
                                        material that violates, any provision of these Terms of Use,
                                        or any applicable law or regulation.
                                        <br />
                                        <br />

                                        Any use of the Ingomu Services in violation of the foregoing
                                        violates these Terms of Use and may result in, among other
                                        things, termination or suspension of your rights to use the
                                        Ingomu Services. If you discover a violation, please email us
                                        immediately at
                                        <a href="mailto:Contact@Ingomu.com">Contact@Ingomu.com</a> or
                                        call us at 720-634-5283.
                                        <br />
                                        <br />
                                        8. CONTRIBUTION LICENSE
                                        <br />
                                        <br />
                                        By posting your Contributions to any part of the Ingomu
                                        Services, you automatically grant, and you represent and
                                        warrant that you have the right to grant, to us an
                                        unrestricted, unlimited, irrevocable, perpetual,
                                        non-exclusive, transferable, royalty-free, fully-paid,
                                        worldwide right, and license to host, use, copy, reproduce,
                                        disclose, sell, resell, publish, broadcast, retitle, archive,
                                        store, cache, publicly perform, publicly display, reformat,
                                        translate, transmit, excerpt (in whole or in part), and
                                        distribute such Contributions (including, without limitation,
                                        your image and voice) for any purpose, commercial,
                                        advertising, or otherwise, and to prepare derivative works of,
                                        or incorporate into other works, such Contributions, and grant
                                        and authorize sublicenses of the foregoing. The use and
                                        distribution may occur in any media formats and through any
                                        media channels.
                                        <br />
                                        <br />
                                        This license will apply to any form, media, or technology now
                                        known or hereafter developed, and includes our use of your
                                        name, company name, and franchise name, as applicable, and any
                                        of the trademarks, service marks, trade names, logos, and
                                        personal and commercial images you provide. You waive all
                                        moral rights in your Contributions, and you warrant those
                                        moral rights have not otherwise been asserted in your
                                        Contributions.
                                        <br />
                                        <br />
                                        We do not assert any ownership over your Contributions. You
                                        retain full ownership of all of your Contributions and any
                                        intellectual property rights or other proprietary rights
                                        associated with your Contributions. We are not liable for any
                                        statements or representations in your Contributions provided
                                        by you in any area on the Ingomu Services. You are solely
                                        responsible for your Contributions to the Ingomu Services and
                                        you expressly agree to exonerate us from any and all
                                        responsibility and to refrain from any legal action against us
                                        regarding your Contributions.
                                        <br />
                                        <br />
                                        We have the right, in our sole and absolute discretion, (1) to
                                        edit, redact, or otherwise change any Contributions; (2) to
                                        re-categorize any Contributions to place them in more
                                        appropriate locations on the Ingomu Services; and (3) to
                                        pre-screen or delete any Contributions at any time and for any
                                        reason, without notice. We have no obligation to monitor your
                                        Contributions.
                                        <br />
                                        <br />
                                        9. SUBMISSIONS
                                        <br />
                                        <br />
                                        You acknowledge and agree that any questions, comments,
                                        suggestions, ideas, feedback, or other information regarding
                                        the Ingomu Services (“Submissions”) provided by you to us are
                                        non-confidential and shall become our sole property. We shall
                                        own exclusive rights, including all intellectual property
                                        rights, and shall be entitled to the unrestricted use and
                                        dissemination of these Submissions for any lawful purpose,
                                        commercial or otherwise, without acknowledgment or
                                        compensation to you. You hereby waive all moral rights to any
                                        such Submissions, and you hereby warrant that any such
                                        Submissions are original with you or that you have the right
                                        to submit such Submissions. You agree there shall be no
                                        recourse against us for any alleged or actual infringement or
                                        misappropriation of any proprietary right in your Submissions.
                                        <br />
                                        <br />
                                        10. THIRD-PARTY WEBSITES AND CONTENT
                                        <br />
                                        <br />
                                        The Ingomu Services may contain (or you may be sent via the
                                        Ingomu Services) links to other websites (“Third-Party
                                        Websites”) as well as articles, photographs, text, graphics,
                                        pictures, designs, music, sound, video, information,
                                        applications, software, and other content or items belonging
                                        to or originating from third parties (“Third-Party Content”).
                                        Such Third-Party Websites and Third-Party Content are not
                                        investigated, monitored, or checked for accuracy,
                                        appropriateness, or completeness by us, and we are not
                                        responsible for any Third-Party Websites accessed through the
                                        Ingomu Services or any Third-Party Content posted on,
                                        available through, or installed from the Ingomu Services,
                                        including the content, accuracy, offensiveness, opinions,
                                        reliability, privacy practices, or other policies of or
                                        contained in the Third-Party Websites or the Third-Party
                                        Content. Inclusion of, linking to, or permitting the use or
                                        installation of any Third-Party Websites or any Third-Party
                                        Content does not imply approval or endorsement thereof by us.
                                        If you decide to leave the Ingomu Services and access the
                                        Third-Party Websites or to use or install any Third-Party
                                        Content, you do so at your own risk, and you should be aware
                                        these Terms of Use no longer govern. You should review the
                                        applicable terms and policies, including privacy and data
                                        gathering practices, of any website to which you navigate from
                                        the Ingomu Services or relating to any applications you use or
                                        install from the Ingomu Services. Any purchases you make
                                        through Third-Party Websites will be through other websites
                                        and from other companies, and we take no responsibility
                                        whatsoever in relation to such purchases which are exclusively
                                        between you and the applicable third party. You agree and
                                        acknowledge that we do not endorse the products or services
                                        offered on Third-Party Websites and you shall hold us harmless
                                        from any harm caused by your purchase of such products or
                                        services. Additionally, you shall hold us harmless from any
                                        losses sustained by you or harm caused to you relating to or
                                        resulting in any way from any Third-Party Content or any
                                        contact with Third-Party Websites.
                                        <br />
                                        <br />
                                        11. U.S. GOVERNMENT RIGHTS
                                        <br />
                                        <br />
                                        Our services are “commercial items” as defined in Federal
                                        Acquisition Regulation (“FAR”) 2.101. If our services are
                                        acquired by or on behalf of any agency not within the
                                        Department of Defense (“DOD”), our services are subject to the
                                        terms of these Terms of Use in accordance with FAR 12.212 (for
                                        computer software) and FAR 12.211 (for technical data). If our
                                        services are acquired by or on behalf of any agency within the
                                        Department of Defense, our services are subject to the terms
                                        of these Terms of Use in accordance with Defense Federal
                                        Acquisition Regulation (“DFARS”) 227.7202 3. In addition,
                                        DFARS252.227 7015 applies to technical data acquired by the
                                        DOD. This U.S. Government Rights clause is in lieu of, and
                                        supersedes, any other FAR, DFARS, or other clause or provision
                                        that addresses government rights in computer software or
                                        technical data under these Terms of Use.
                                        <br />
                                        <br />
                                        12. SERVICES MANAGEMENT
                                        <br />
                                        <br />
                                        We reserve the right, but not the obligation, to: (1) monitor
                                        the Ingomu Services for violations of these Terms of Use; (2)
                                        take appropriate legal action against anyone who, in our sole
                                        discretion, violates the law or these Terms of Use, including
                                        without limitation, reporting such user to law enforcement
                                        authorities; (3) in our sole discretion and without
                                        limitation, refuse, restrict access to, limit the availability
                                        of, or disable (to the extent technologically feasible) any of
                                        your Contributions or any portion thereof; (4) in our sole
                                        discretion and without limitation, notice, or liability, to
                                        remove from the Ingomu Services or otherwise disable all files
                                        and content that are excessive in size or are in any way
                                        burdensome to our systems; and (5) otherwise manage the Ingomu
                                        Services in a manner designed to protect our rights and
                                        property and to facilitate the proper functioning of the
                                        Ingomu Services.
                                        <br />
                                        <br />
                                        13. PRIVACY POLICY
                                        <br />
                                        <br />
                                        We care about data privacy and security. Please review our
                                        Privacy Policy below. By using the Ingomu Services, you agree
                                        to be bound by our Privacy Policy. Further, we do not
                                        knowingly accept, request, or solicit information from
                                        children or knowingly market to children. Therefore, in
                                        accordance with the U.S. Children’s Online Privacy Protection
                                        Act, if we receive actual knowledge that anyone under the age
                                        of 13 has provided personal information to us without the
                                        requisite and verifiable parental consent, we will delete that
                                        information from the Ingomu Services as quickly as is
                                        reasonably practical.
                                        <br />
                                        <br />
                                        14. DIGITAL MILLENNIUM COPYRIGHT ACT (DMCA) NOTICE AND POLICY
                                        <br />
                                        <br />
                                        A. Notifications
                                        <br />
                                        <br />
                                        We respect the intellectual property rights of others. If you
                                        believe that any material available on or through the Ingomu
                                        Services infringes upon any copyright you own or control,
                                        please immediately notify our Designated Copyright Agent using
                                        the contact information provided below (a “Notification”). A
                                        copy of your Notification will be sent to the person who
                                        posted or stored the material addressed in the Notification.
                                        Please be advised that pursuant to federal law you may be held
                                        liable for damages if you make material misrepresentations in
                                        a Notification. Thus, if you are not sure that material
                                        located on or linked to by the Ingomu Services infringes your
                                        copyright, you should consider first contacting an attorney.
                                        <br />
                                        <br />
                                        All Notifications should meet the requirements of DMCA 17
                                        U.S.C. § 512(c)(3) and include the following information: (1)
                                        A physical or electronic signature of a person authorized to
                                        act on behalf of the owner of an exclusive right that is
                                        allegedly infringed; (2) identification of the copyrighted
                                        work claimed to have been infringed, or, if multiple
                                        copyrighted works on the Ingomu Services are covered by the
                                        Notification, a representative list of such works on the
                                        Ingomu Services; (3) identification of the material that is
                                        claimed to be infringing or to be the subject of infringing
                                        activity and that is to be removed or access to which is to be
                                        disabled, and information reasonably sufficient to permit us
                                        to locate the material; (4) information reasonably sufficient
                                        to permit us to contact the complaining party, such as an
                                        address, telephone number, and, if available, an email address
                                        at which the complaining party may be contacted; (5) a
                                        statement that the complaining party has a good faith belief
                                        that use of the material in the manner complained of is not
                                        authorized by the copyright owner, its agent, or the law; and
                                        (6) a statement that the information in the notification is
                                        accurate, and under penalty of perjury, that the complaining
                                        party is authorized to act on behalf of the owner of an
                                        exclusive right that is allegedly infringed upon.
                                        <br />
                                        <br />
                                        B. Counter Notification
                                        <br />
                                        <br />
                                        If you believe your own copyrighted material has been removed
                                        from the Ingomu Services as a result of a mistake or
                                        misidentification, you may submit a written counter
                                        notification to [us/our Designated Copyright Agent] using the
                                        contact information provided below (a “Counter Notification”).
                                        To be an effective Counter Notification under the DMCA, your
                                        Counter Notification must include substantially the following:
                                        (1) identification of the material that has been removed or
                                        disabled and the location at which the material appeared
                                        before it was removed or disabled; (2) a statement that you
                                        consent to the jurisdiction of the Federal District Court in
                                        which your address is located, or if your address is outside
                                        the United States, for any judicial district in which we are
                                        located; (3) a statement that you will accept service of
                                        process from the party that filed the Notification or the
                                        party’s agent; (4) your name, address, and telephone number;
                                        (5) a statement under penalty of perjury that you have a good
                                        faith belief that the material in question was removed or
                                        disabled as a result of a mistake or misidentification of the
                                        material to be removed or disabled; and (6) your physical or
                                        electronic signature.
                                        <br />
                                        <br />
                                        If you send us a valid, written Counter Notification meeting
                                        the requirements described above, we will restore your removed
                                        or disabled material, unless we first receive notice from the
                                        party filing the Notification informing us that such party has
                                        filed a court action to restrain you from engaging in
                                        infringing activity related to the material in question.
                                        Please note that if you materially misrepresent that the
                                        disabled or removed content was removed by mistake or
                                        misidentification, you may be liable for damages, including
                                        costs and attorney’s fees. Filing a false Counter Notification
                                        constitutes perjury.
                                        <br />
                                        <br />
                                        Designated Copyright Agent:
                                        <br />
                                        Ingomu Learning LLC
                                        <br />
                                        Attn: Copyright Agent
                                        <br />
                                        1550 Larimer Street, Ste 465
                                        <br />
                                        Denver, CO 80202
                                        <br />
                                        <a href="mailto:Legal@Ingomu.com">Legal@Ingomu.com</a>
                                        <br />
                                        <br />
                                        15. TERM AND TERMINATION
                                        <br />
                                        <br />
                                        These Terms of Use shall remain in full force and effect while
                                        you use the Ingomu Services.
                                        <br />
                                        <br />
                                        WITHOUT LIMITING ANY OTHER PROVISION OF THESE TERMS OF USE, WE
                                        RESERVE THE RIGHT TO, IN OUR SOLE DISCRETION AND WITHOUT
                                        NOTICE OR LIABILITY, DENY ACCESS TO AND USE OF THE INGOMU
                                        SERVICES (INCLUDING BLOCKING CERTAIN IP ADDRESSES), TO ANY
                                        PERSON FOR ANY REASON OR FOR NO REASON, INCLUDING WITHOUT
                                        LIMITATION FOR BREACH OF ANY REPRESENTATION, WARRANTY, OR
                                        COVENANT CONTAINED IN THESE TERMS OF USE OR OF ANY APPLICABLE
                                        LAW OR REGULATION. WE MAY TERMINATE YOUR USE OR PARTICIPATION
                                        IN THE INGOMU SERVICES OR DELETE YOUR ACCOUNT AND ANY CONTENT
                                        OR INFORMATION THAT YOU POSTED AT ANY TIME, WITHOUT WARNING,
                                        IN OUR SOLE DISCRETION.
                                        <br />
                                        <br />
                                        If we terminate or suspend your account for any reason, you
                                        are prohibited from registering and creating a new account
                                        under your name, a fake or borrowed name, or the name of any
                                        third party, even if you may be acting on behalf of the third
                                        party. In addition to terminating or suspending your account,
                                        we reserve the right to take appropriate legal action,
                                        including without limitation pursuing civil, criminal, and
                                        injunctive redress.
                                        <br />
                                        <br />
                                        16. MODIFICATIONS AND INTERRUPTIONS
                                        <br />
                                        <br />
                                        We reserve the right to change, modify, or remove the contents
                                        of the Ingomu Services at any time or for any reason at our
                                        sole discretion without notice. However, we have no obligation
                                        to update any information on our Ingomu Services. We also
                                        reserve the right to modify or discontinue all or part of the
                                        Ingomu Services without notice at any time. We will not be
                                        liable to you or any third party for any modification, price
                                        change, suspension, or discontinuance of the Ingomu Services.
                                        We cannot guarantee the Ingomu Services will be available at
                                        all times. We may experience hardware, software, or other
                                        problems or need to perform maintenance related to the Ingomu
                                        Services, resulting in interruptions, delays, or errors. We
                                        reserve the right to change, revise, update, suspend,
                                        discontinue, or otherwise modify the Ingomu Services at any
                                        time or for any reason without notice to you. You agree that
                                        we have no liability whatsoever for any loss, damage, or
                                        inconvenience caused by your inability to access or use the
                                        Ingomu Services during any downtime or discontinuance of the
                                        Ingomu Services. Nothing in these Terms of Use will be
                                        construed to obligate us to maintain and support the Site or
                                        to supply any corrections, updates, or releases in connection
                                        therewith.
                                        <br />
                                        <br />
                                        17. GOVERNING LAW
                                        <br />
                                        <br />
                                        These Terms of Use and your use of the Ingomu Services are
                                        governed by and construed in accordance with the laws of the
                                        State of Colorado applicable to agreements made and to be
                                        entirely performed within the State of Colorado, without
                                        regard to its conflict of law principles.
                                        <br />
                                        <br />
                                        18. DISPUTE RESOLUTION
                                        <br />
                                        <br />
                                        A. Informal Negotiations
                                        <br />
                                        <br />
                                        To expedite resolution and control the cost of any dispute,
                                        controversy, or claim related to these Terms of Use (each a
                                        “Dispute” and collectively, the “Disputes”) brought by either
                                        you or us (individually, a “Party” and collectively, the
                                        “Parties”), the Parties agree to first attempt to negotiate
                                        any Dispute (except those Disputes expressly provided below)
                                        informally for at least thirty (30) days before initiating
                                        arbitration. Such informal negotiations commence upon written
                                        notice from one Party to the other Party.
                                        <br />
                                        <br />
                                        B. Binding Arbitration
                                        <br />
                                        <br />
                                        If the Parties are unable to resolve a Dispute through
                                        informal negotiations, the Dispute (except those Disputes
                                        expressly excluded below) will be finally and exclusively
                                        resolved by binding arbitration. YOU UNDERSTAND THAT WITHOUT
                                        THIS PROVISION, YOU WOULD HAVE THE RIGHT TO SUE IN COURT AND
                                        HAVE A JURY TRIAL. The arbitration shall be commenced and
                                        conducted under the Commercial Arbitration Rules of the
                                        American Arbitration Association (“AAA”) and, where
                                        appropriate, the AAA’s Supplementary Procedures for Consumer
                                        Related Disputes (“AAA Consumer Rules”), both of which are
                                        available at the AAA website www.adr.org. Your arbitration
                                        fees and your share of arbitrator compensation shall be
                                        governed by the AAA Consumer Rules and, where appropriate,
                                        limited by the AAA Consumer Rules. If such costs are
                                        determined by the arbitrator to be excessive, we will pay all
                                        arbitration fees and expenses. The arbitration may be
                                        conducted in person, through the submission of documents, by
                                        phone, or online. The arbitrator will make a decision in
                                        writing but need not provide a statement of reasons unless
                                        requested by either Party. The arbitrator must follow
                                        applicable law, and any award may be challenged if the
                                        arbitrator fails to do so. Except where otherwise required by
                                        the applicable AAA rules or applicable law, the arbitration
                                        will take place in Boulder County, CO. Except as otherwise
                                        provided herein, the Parties may litigate in court to compel
                                        arbitration, stay proceedings pending arbitration, or to
                                        confirm, modify, vacate, or enter judgment on the award
                                        entered by the arbitrator.
                                        <br />
                                        <br />
                                        If for any reason, a Dispute proceeds in court rather than
                                        arbitration, the Dispute shall be commenced or prosecuted in
                                        the state and federal courts located in Boulder County, CO,
                                        and the Parties hereby consent to, and waive all defenses of
                                        lack of personal jurisdiction, and forum non conveniens with
                                        respect to venue and jurisdiction in such state and federal
                                        courts. Application of the United Nations Convention on
                                        Contracts for the International Sale of Goods and the Uniform
                                        Computer Information Transaction Act (UCITA) are excluded from
                                        these Terms of Use.
                                        <br />
                                        <br />
                                        In no event shall any Dispute brought by either Party related
                                        in any way to the Site be commenced more than one (1) years
                                        after the cause of action arose. If this provision is found to
                                        be illegal or unenforceable, then neither Party will elect to
                                        arbitrate any Dispute falling within that portion of this
                                        provision found to be illegal or unenforceable and such
                                        Dispute shall be decided by a court of competent jurisdiction
                                        within the courts listed for jurisdiction above, and the
                                        Parties agree to submit to the personal jurisdiction of that
                                        court.
                                        <br />
                                        <br />
                                        C. Restrictions
                                        <br />
                                        <br />
                                        The Parties agree that any arbitration shall be limited to the
                                        Dispute between the Parties individually. To the full extent
                                        permitted by law, (a) no arbitration shall be joined with any
                                        other proceeding; (b) there is no right or authority for any
                                        Dispute to be arbitrated on a class-action basis or to utilize
                                        class action procedures; and (c) there is no right or
                                        authority for any Dispute to be brought in a purported
                                        representative capacity on behalf of the general public or any
                                        other persons.
                                        <br />
                                        <br />
                                        D. Exceptions to Informal Negotiations and Arbitration
                                        <br />
                                        <br />
                                        The Parties agree that the following Disputes are not subject
                                        to the above provisions concerning informal negotiations and
                                        binding arbitration: (a) any Disputes seeking to enforce or
                                        protect, or concerning the validity of, any of the
                                        intellectual property rights of a Party; (b) any Dispute
                                        related to, or arising from, allegations of theft, piracy,
                                        invasion of privacy, or unauthorized use; and (c) any claim
                                        for injunctive relief. If this provision is found to be
                                        illegal or unenforceable, then neither Party will elect to
                                        arbitrate any Dispute falling within that portion of this
                                        provision found to be illegal or unenforceable and such
                                        Dispute shall be decided by a court of competent jurisdiction
                                        within the courts listed for jurisdiction above, and the
                                        Parties agree to submit to the personal jurisdiction of that
                                        court.
                                        <br />
                                        <br />
                                        19. CORRECTIONS
                                        <br />
                                        <br />
                                        There may be information on the Ingomu Services that contains
                                        typographical errors, inaccuracies, or omissions, including
                                        descriptions, pricing, availability, and various other
                                        information. We reserve the right to correct any errors,
                                        inaccuracies, or omissions and to change or update the
                                        information on the Ingomu Services at any time, without prior
                                        notice.
                                        <br />
                                        <br />
                                        20. DISCLAIMER
                                        <br />
                                        <br />
                                        THE INGOMU SERVICES ARE PROVIDED ON AN AS-IS AND AS-AVAILABLE
                                        BASIS. YOU AGREE THAT YOUR USE OF THE INGOMU SERVICES WILL BE
                                        AT YOUR SOLE RISK. TO THE FULLEST EXTENT PERMITTED BY LAW, WE
                                        DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, IN CONNECTION
                                        WITH THE INGOMU SERVICES AND YOUR USE THEREOF, INCLUDING,
                                        WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF MERCHANTABILITY,
                                        FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. WE
                                        MAKE NO WARRANTIES OR REPRESENTATIONS ABOUT THE ACCURACY OR
                                        COMPLETENESS OF THE INGOMU SERVICES’ CONTENT OR THE CONTENT OF
                                        ANY WEBSITES LINKED TO THE INGOMU SERVICES AND WE WILL ASSUME
                                        NO LIABILITY OR RESPONSIBILITY FOR ANY (1) ERRORS, MISTAKES,
                                        OR INACCURACIES OF CONTENT AND MATERIALS, (2) PERSONAL INJURY
                                        OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM
                                        YOUR ACCESS TO AND USE OF THE INGOMU SERVICES, (3) ANY
                                        UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS AND/OR ANY
                                        AND ALL PERSONAL INFORMATION AND/OR FINANCIAL INFORMATION
                                        STORED THEREIN, (4) ANY INTERRUPTION OR CESSATION OF
                                        TRANSMISSION TO OR FROM THE INGOMU SERVICES, (5) ANY BUGS,
                                        VIRUSES, TROJAN HORSES, OR THE LIKE WHICH MAY BE TRANSMITTED
                                        TO OR THROUGH THE INGOMU SERVICES BY ANY THIRD PARTY, AND/OR
                                        (6) ANY ERRORS OR OMISSIONS IN ANY CONTENT AND MATERIALS OR
                                        FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE
                                        USE OF ANY CONTENT POSTED, TRANSMITTED, OR OTHERWISE MADE
                                        AVAILABLE VIA THE INGOMU SERVICES. WE DO NOT WARRANT, ENDORSE,
                                        GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY PRODUCT OR SERVICE
                                        ADVERTISED OR OFFERED BY A THIRD PARTY THROUGH THE INGOMU
                                        SERVICES, ANY HYPERLINKED WEBSITE, OR ANY WEBSITE OR MOBILE
                                        APPLICATION FEATURED IN ANY BANNER OR OTHER ADVERTISING, AND
                                        WE WILL NOT BE A PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR
                                        MONITORING ANY TRANSACTION BETWEEN YOU AND ANY THIRD-PARTY
                                        PROVIDERS OF PRODUCTS OR SERVICES. AS WITH THE PURCHASE OF A
                                        PRODUCT OR SERVICE THROUGH ANY MEDIUM OR IN ANY ENVIRONMENT,
                                        YOU SHOULD USE YOUR BEST JUDGMENT AND EXERCISE CAUTION WHERE
                                        APPROPRIATE.
                                        <br />
                                        <br />
                                        21. LIMITATIONS OF LIABILITY
                                        <br />
                                        <br />
                                        IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, CONTRACTORS
                                        OR AGENTS BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY DIRECT,
                                        INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL, OR
                                        PUNITIVE DAMAGES, INCLUDING LOST PROFIT, LOST REVENUE, LOSS OF
                                        DATA, OR OTHER DAMAGES ARISING FROM YOUR USE OF THE INGOMU
                                        SERVICES, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF
                                        SUCH DAMAGES. NOTWITHSTANDING ANYTHING TO THE CONTRARY
                                        CONTAINED HEREIN, OUR LIABILITY TO YOU FOR ANY CAUSE
                                        WHATSOEVER AND REGARDLESS OF THE FORM OF THE ACTION, WILL AT
                                        ALL TIMES BE LIMITED TO THE AMOUNT PAID, IF ANY, BY YOU TO US
                                        DURING YOUR CURRENT SUBSCRIPTION PERIOD PERIOD PRIOR TO ANY
                                        CAUSE OF ACTION ARISING. CERTAIN STATE LAWS DO NOT ALLOW
                                        LIMITATIONS ON IMPLIED WARRANTIES OR THE EXCLUSION OR
                                        LIMITATION OF CERTAIN DAMAGES. IF THESE LAWS APPLY TO YOU,
                                        SOME OR ALL OF THE ABOVE DISCLAIMERS OR LIMITATIONS MAY NOT
                                        APPLY TO YOU, AND YOU MAY HAVE ADDITIONAL RIGHTS.
                                        <br />
                                        <br />
                                        22. INDEMNIFICATION
                                        <br />
                                        <br />
                                        You agree to defend, indemnify, and hold us harmless,
                                        including our subsidiaries, affiliates, and all of our
                                        respective officers, agents, contractors, partners, and
                                        employees, from and against any loss, damage, liability,
                                        claim, or demand, including reasonable attorneys’ fees and
                                        expenses, made by any third party due to or arising out of:
                                        (1) your Contributions; (2) use of the Ingomu Services; (3)
                                        breach of these Terms of Use; (4) any breach of your
                                        representations and warranties set forth in these Terms of
                                        Use; (5) your violation of the rights of a third party,
                                        including but not limited to intellectual property rights; or
                                        (6) any overt harmful act toward any other user of the Ingomu
                                        Services with whom you connected via the Ingomu Services.
                                        Notwithstanding the foregoing, we reserve the right, at your
                                        expense, to assume the exclusive defense and control of any
                                        matter for which you are required to indemnify us, and you
                                        agree to cooperate, at your expense, with our defense of such
                                        claims. We will use reasonable efforts to notify you of any
                                        such claim, action, or proceeding which is subject to this
                                        indemnification upon becoming aware of it.
                                        <br />
                                        <br />
                                        23. USER DATA
                                        <br />
                                        <br />
                                        We will maintain certain data that you transmit to the Ingomu
                                        Services for the purpose of managing the performance of the
                                        Ingomu Services, as well as data relating to your use of the
                                        Ingomu Services. Although we perform regular routine backups
                                        of data, you are solely responsible for all data that you
                                        transmit or that relates to any activity you have undertaken
                                        using the Ingomu Services. You agree that we shall have no
                                        liability to you for any loss or corruption of any such data,
                                        and you hereby waive any right of action against us arising
                                        from any such loss or corruption of such data.
                                        <br />
                                        <br />
                                        24. ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES
                                        <br />
                                        <br />
                                        Visiting the Ingomu Services, sending us emails, engaging with
                                        users, and completing online forms constitute electronic
                                        communications. You consent to receive electronic
                                        communications, and you agree that all agreements, notices,
                                        disclosures, and other communications we provide to you
                                        electronically, via email and on via the Ingomu Services,
                                        satisfy any legal requirement that such communication be in
                                        writing. YOU HEREBY AGREE TO THE USE OF ELECTRONIC SIGNATURES,
                                        CONTRACTS, ORDERS, AND OTHER RECORDS, AND TO ELECTRONIC
                                        DELIVERY OF NOTICES, POLICIES, AND RECORDS OF TRANSACTIONS
                                        INITIATED OR COMPLETED BY US OR VIA THE INGOMU SERVICES. You
                                        hereby waive any rights or requirements under any statutes,
                                        regulations, rules, ordinances, or other laws in any
                                        jurisdiction which require an original signature or delivery
                                        or retention of non-electronic records, or to payments or the
                                        granting of credits by any means other than electronic means.
                                        <br />
                                        <br />
                                        25. CALIFORNIA USERS AND RESIDENTS
                                        <br />
                                        <br />
                                        If any complaint with us is not satisfactorily resolved, you
                                        can contact the Complaint Assistance Unit of the Division of
                                        Consumer Services of the California Department of Consumer
                                        Affairs in writing at 1625 North Market Blvd., Suite N 112,
                                        Sacramento, California 95834 or by telephone at (800)
                                        952-5210.
                                        <br />
                                        <br />
                                        26. MISCELLANEOUS
                                        <br />
                                        <br />
                                        These Terms of Use and any policies or operating rules posted
                                        by us on the Ingomu Services or in respect to the Ingomu
                                        Services constitute the entire agreement and understanding
                                        between you and us. Our failure to exercise or enforce any
                                        right or provision of these Terms of Use shall not operate as
                                        a waiver of such right or provision. These Terms of Use
                                        operate to the fullest extent permissible by law. We may
                                        assign any or all of our rights and obligations to others at
                                        any time. We shall not be responsible or liable for any loss,
                                        damage, delay, or failure to act caused by any cause beyond
                                        our reasonable control. If any provision or part of a
                                        provision of these Terms of Use is determined to be unlawful,
                                        void, or unenforceable, that provision or part of the
                                        provision is deemed severable from these Terms of Use and does
                                        not affect the validity and enforceability of any remaining
                                        provisions. There is no joint venture, partnership, employment
                                        or agency relationship created between you and us as a result
                                        of these Terms of Use or use of the Ingomu Services. You agree
                                        that these Terms of Use will not be construed against us by
                                        virtue of having drafted them. You hereby waive any and all
                                        defenses you may have based on the electronic form of these
                                        Terms of Use and the lack of signing by the parties hereto to
                                        execute these Terms of Use.
                                        <br />
                                        <br />
                                        Coaches hosting a Learning Community on the Ingomu Services
                                        must agree to an additional Term of Service Agreement
                                        governing the hosting, publication, management, and
                                        communications for being part of the Ingomu Services.
                                        <br />
                                        <br />
                                        27. CONTACT US
                                        <br />
                                        <br />
                                        In order to resolve a complaint regarding the Ingomu Services
                                        or to receive further information regarding use of the Ingomu
                                        Services, please contact us at:
                                        <br />
                                        <br />
                                        Ingomu Learning LLC
                                        <br />
                                        1550 Larimer Street, Ste. 465
                                        <br />
                                        Denver, CO 80202
                                        <br />
                                        United States
                                        <br />
                                        <a href="mailto:Contact@Ingomu.com">Contact@Ingomu.com</a>
                                        <br />
                                        Phone: 720.634.5283
                                        <div id="coaching"></div>
                                    </div>
                                    <div id="opt2">
                                        <span class="title-font" style="color: #3b5998">INGOMU LIVE COACHING TERMS OF
                                            SERVICE</span>
                                        <a href="#top" style="float: right"><span class="title-font">Back to
                                                Top</span></a><br />
                                        Last Updated, September 14, 2021
                                        <br />
                                        <br />
                                        We offer interactive coaching services that connect you with
                                        professional coaches (“Coaches”) via the Ingomu App, and
                                        information to help you achieve your career advancement,
                                        personal development, and health and wellness goals
                                        (collectively the “Coaching Services”). Your use of the
                                        Coaching Services is governed by the Ingomu Terms of Service,
                                        which incorporate these Coaching Terms of Service (“Coaching
                                        Terms”). The Ingomu Terms for Paid Services apply to your
                                        purchase of the Coaching Services.
                                        <br />
                                        <br />
                                        1. COACHING SERVICES
                                        <br />
                                        <br />
                                        The Coaching Services provides tools that help you meet your
                                        career advancement, personal development, and health and
                                        wellness goals. The Coaching Services are not a medical or
                                        health care service, nor a legal, psychological, or investment
                                        service. Although some Coaches may be licensed healthcare
                                        practitioners, they do not provide health care services
                                        through the Coaching Services. The Coaching Services are not
                                        intended to be a substitute for professional medical
                                        consultation, advice, or treatment from qualified physicians.
                                        It is not a substitute for legal, psychological or investment
                                        advice from qualified professionals. Ingomu Learning, LLC does
                                        not endorse any specific tests, physician or medical
                                        professional, products, procedures, opinions, or any other
                                        information that may be mentioned on the Coaching Services. We
                                        recommend that you consult your doctor before using the
                                        Coaching Services for medical reasons. If you think that you
                                        have a medical emergency, call your doctor or 911 immediately.
                                        <br />
                                        <br />
                                        2. COACHES
                                        <br />
                                        <br />
                                        The Coaching Services connect you with coaches who will work
                                        with you via live virtual group sessions and help you set
                                        goals and provide support and guidance in achieving them.
                                        Coaches may interact with you in a variety of ways, including
                                        via live virtual group sessions, and in-app chat or
                                        discussions.
                                        <br />
                                        <br />
                                        Coaches are subject to vetting. They are contractually bound
                                        to keep your information confidential and agree not to misuse
                                        information about you. Occasionally, a Coach’s availability
                                        may change, and we reserve the right to remove them with or
                                        without notice to you.
                                        <br />
                                        <br />
                                        If a Coach engages in inappropriate behavior, please report it
                                        to us. When interacting with a Coach, you agree to be
                                        professional and avoid engaging in inappropriate behavior.
                                        <br />
                                        <br />
                                        3. DISCLAIMERS ABOUT THE COACHING SERVICES
                                        <br />
                                        <br />
                                        The Coaching Services, are provided “as-is”, without warranty
                                        of any kind. Without limiting the foregoing or any other terms
                                        or agreements you have with us, we explicitly disclaim all
                                        express or implied warranties arising out of or related to
                                        these Coaching Terms or the Coaching Services, including any
                                        warranties of merchantability, fitness for a particular
                                        purpose, quiet enjoyment, or non-infringement, and any
                                        warranties arising out of the course of dealing or usage of
                                        trade. We make no warranty that the Coaching Services will
                                        meet your requirements or be available on an uninterrupted,
                                        secure, or error-free basis.
                                        <br />
                                        <br />
                                        This means that while the Coaching Services are intended to
                                        help you pursue your career advancement, personal development,
                                        and health and wellness goals, we do not guarantee that you
                                        will achieve your goals. We make no endorsement,
                                        representation, or warranty of any kind about any information
                                        on the Coaching Services, whether provided to you by a Coach
                                        or any content available through the Coaching Services. We are
                                        not responsible for the accuracy, reliability, availability,
                                        effectiveness, or correct use of information you receive via
                                        the Coaching Services and we are not responsible for any
                                        health or other problems that may result from your reliance on
                                        the Coaching Services. If you engage in any health, fitness,
                                        or wellness program or activities that you learn about through
                                        the Coaching Services, you agree that you do so voluntarily
                                        and at your own risk. If you experience a medical emergency
                                        while using the Coaching Services, consult with a medical
                                        professional immediately.
                                    </div>
                                    <div id="opt3">
                                        <span class="title-font" style="color: #3b5998">INGOMU TERMS FOR PAID SERVICE
                                        </span>
                                        <a href="#top" style="float: right"><span class="title-font">Back to
                                                Top</span></a><br />
                                        Last Updated, September 14, 2021
                                        <br />
                                        <br />
                                        Paid Services include content and personalized, interactive
                                        services via the Ingomu App. They may be automatically
                                        renewing subscription services, like access to our Coaching
                                        Services (“Subscriptions”) via the Ingomu App. We may make
                                        changes to, suspend, or discontinue Paid Services at any time
                                        for any reason. We have the sole discretion to determine which
                                        portions of the Ingomu Services require payment. 1.
                                        SUBSCRIPTIONS
                                        <br />
                                        <br />
                                        A. Automatically Renewing Subscriptions. Your Subscription
                                        term may vary with monthly or annual automatic renewal terms
                                        (a “Subscription Term”), as described in the course of your
                                        transaction. Your Subscription will automatically renew for
                                        additional Subscription Terms as long as your Subscription
                                        continues, until you cancel it or we suspend or stop providing
                                        the Subscription in accordance with our Terms of Service.
                                        Unless otherwise indicated by us, you will be charged prior
                                        to, or at the beginning of, each renewal term.
                                        <br />
                                        <br />
                                        B. Subscription Cancellation or Subscription Term Changes. You
                                        may cancel your Subscription at any time. Your cancellation
                                        will take effect at the end of the current Subscription Term.
                                        You can cancel your Ingomu Subscription Plan by going to the
                                        Google Play Store App on your device. Tap your profile avatar,
                                        then select Payments and subscriptions. Select Ingomu and
                                        follow the instructions to cancel your plan. Make sure you
                                        cancel your plan no less than 24 hours prior to your renewal
                                        date to ensure you do not get billed by the Google Play Store.
                                        <br />
                                        <br />
                                        You can change your Ingomu Subscription Plan by going to the
                                        Google Play Store App on your device. Tap your profile avatar,
                                        then select Payments and subscriptions. Select Ingomu to
                                        update and follow the instructions to update your plan.
                                        <br />
                                        <br />
                                        In the event you cancel your Subscription, note that we may
                                        still send you promotional communications about Ingomu, unless
                                        you opt out of receiving those communications by following the
                                        unsubscribe instructions provided in the communications.
                                        <br />
                                        <br />
                                        C. No Refunds on Subscriptions. When you cancel a
                                        Subscription, you cancel only future charges for your
                                        Subscription. You will not receive a refund for the current
                                        Subscription Term you paid for, but you will continue to have
                                        full access to that Subscription until the end of that current
                                        Subscription Term. At any time for any reason, we may provide
                                        a refund, discount, or other consideration (“credits”) to some
                                        or all of our users. The amount and form of such credits, and
                                        the decision to provide them, are at our sole and absolute
                                        discretion. The provision of credits in one instance does not
                                        entitle you to credits in the future for similar instances,
                                        nor does it obligate us to provide credits in the future.
                                        <br />
                                        <br />
                                        D. Free Trials. From time to time, we may offer free trials of
                                        certain Subscriptions for specified periods of time without
                                        payment. If we offer you a free trial, the specific terms of
                                        your free trial will be provided in the marketing materials
                                        describing the particular trial. Once your free trial ends, we
                                        (or our third-party payment processor) will begin billing your
                                        designated payment method on a recurring basis for your
                                        Subscription (plus any applicable taxes and other charges) for
                                        as long as your Subscription continues, unless you cancel your
                                        Subscription prior to the end of your free trial. Instructions
                                        for canceling your Subscription are described in the sections
                                        above. To avoid any charges, you must cancel your Subscription
                                        before the end of your free trial period.
                                        <br />
                                        <br />
                                        E. Price Changes. We reserve the right to adjust pricing for
                                        our Paid Services or any components thereof in any manner and
                                        at any time. Any price changes will take effect following
                                        notice to you.
                                    </div>
                                    <div id="opt4">
                                        <span class="title-font" style="color: #3b5998">PRIVACY POLICY</span>
                                        <a href="#top" style="float: right"><span class="title-font">Back to
                                                Top</span></a><br />
                                        Last Updated, September 14, 2021
                                        <br />
                                        <br />
                                        Ingomu Learning takes data privacy seriously. This privacy
                                        policy explains who we are, how we collect, share and use
                                        Personal Information, and how you can exercise your privacy
                                        rights.
                                        <br />
                                        <br />
                                        We strongly recommend that you read this privacy policy in
                                        full to ensure you are fully informed. If you have any
                                        questions or concerns about our use of your Personal
                                        Information, then please contact us using the contact details
                                        provided in Section 9.
                                        <br />
                                        <br />
                                        1. THE BASICS
                                        <br />
                                        <br />
                                        A. About Us
                                        <br />
                                        <br />
                                        Ingomu is a coaching app (the "Services") operated by Ingomu
                                        Learning LLC, a company headquartered in the State of Colorado
                                        in the United States ("we," "us," "our," "Ingomu" and "Ingomu
                                        Learning").
                                        <br />
                                        <br />
                                        The Services enable our Users to, among other things,
                                        participate in live virtual group coaching sessions, view
                                        recorded sessions on demand, and engage and communicate around
                                        content.
                                        <br />
                                        <br />
                                        B. Key Terms
                                        <br />
                                        <br />
                                        In this privacy policy, these terms have the following
                                        meaning:
                                        <br />
                                        <br />
                                        “Coach” is a person using our Services to distribute content
                                        and coach Users. “User” is a person who has purchased access
                                        to the Ingomu App and is using our Services. “Learning
                                        Community” is a Coach’s community on the app where he/she
                                        hosts sessions and engages with Users. “Personal Information”
                                        means any information that identifies or can be used to
                                        identify a Coach, a User, or a Visitor, directly or
                                        indirectly. Examples of Personal Information include, but are
                                        not limited to, first and last name, email address,
                                        occupation, or other demographic information. “Websites” means
                                        any website(s) we own and operate (such as Ingomu.com) or any
                                        web pages, interactive features, applications, widgets, blogs,
                                        social networks, social network "tabs," or other online,
                                        mobile, or wireless offerings that post a link to this privacy
                                        policy. “Visitor” means any person who visits any of our
                                        websites. "You" and "your" means, depending on the context,
                                        either a Coach, a Learner, or a Visitor.
                                        <br />
                                        <br />
                                        2. PRIVACY
                                        <br />
                                        <br />
                                        A. Information We Collect
                                        <br />
                                        <br />
                                        The Personal Information that we may collect broadly falls
                                        into the following categories:
                                        <br />
                                        <br />
                                        (i) Information you provide to us: In the course of engaging
                                        with our Services, you may provide Personal Information about
                                        you. Personal Information is often, but not exclusively,
                                        provided to us when you sign up for and use the Services,
                                        consult with our customer service team, send us an email,
                                        request a newsletter, integrate the Services with another
                                        website or service, participate in a learning community by
                                        posting discussions, or communicate with us in any other way.
                                        <br />
                                        <br />
                                        We will let you know prior to collection whether the provision
                                        of Personal Information we are collecting is compulsory or if
                                        it may be provided on a voluntary basis and the consequences,
                                        if any, of not providing the information. By giving us this
                                        information, you agree to this information being collected,
                                        used and disclosed as described in our Terms of Use and in
                                        this privacy policy.
                                        <br />
                                        <br />
                                        (ii) Information we collect automatically: In the course of
                                        engaging with our Services we automatically collect your IP
                                        address, your location, your email, phone model, time zone,
                                        when you bookmark or favorite a learning community, where you
                                        leave an on-demand session.
                                        <br />
                                        <br />
                                        B. Use of Personal Information
                                        <br />
                                        <br />
                                        We may use the Personal Information we collect through the
                                        Services or other sources for a range of reasons, including:
                                        <br />
                                        <br />
                                        To provide the Service you signed up for. Our primary goal in
                                        collecting personal information from you is to facilitate
                                        efficient transactions and provide you with the product and
                                        services you are purchasing, and membership to the Ingomu App
                                        for both Coaches and Users. Ingomu Learning LLC will not do
                                        anything to jeopardize your trust, including renting or
                                        selling your personal information.
                                        <br />
                                        <br />
                                        Ingomu Learning LLC will share basic information with either
                                        the Coach or Users. For example, if you use the Ingomu App,
                                        then Ingomu will share your first name and profile picture
                                        with the Coach during a live session or when posting a
                                        comment. Only your first name and profile picture entered can
                                        be seen by other Users and our Coach if you participate in a
                                        live session or post a comment. The general public has no
                                        access to your information.
                                        <br />
                                        <br />
                                        To communicate with you about your account and provide
                                        customer support.
                                        <br />
                                        <br />
                                        To provide, support and improve the Services. For example,
                                        this may include sharing your or your information with third
                                        parties in order to provide and support our Services or to
                                        make certain features of the Services available to you.
                                        <br />
                                        <br />
                                        When we share Personal Information with third parties, we take
                                        steps to protect your information by requiring these third
                                        parties to enter into a contract with us that requires them to
                                        use the Personal Information we transfer to them in a manner
                                        that is consistent with this privacy policy and applicable
                                        privacy laws.
                                        <br />
                                        <br />
                                        To send you system alert messages. For example, we may inform
                                        you about temporary or permanent changes to our Services, such
                                        as planned outages, or send you account, security or
                                        compliance notifications, such as new features, version
                                        updates, releases, abuse warnings, and changes to this privacy
                                        policy.
                                        <br />
                                        <br />
                                        To enforce compliance with our Terms of Use and applicable
                                        laws, and to protect the rights and safety of our Users and
                                        third parties, as well as our own. This may include developing
                                        tools and algorithms that help us prevent violations.
                                        <br />
                                        <br />
                                        For example, sometimes we review the content of our Coaches’
                                        Learning Communities to make sure they comply with our Terms
                                        of Use. To improve that process, we have software that helps
                                        us find content that may violate our Terms of Use. Our
                                        employees or independent contractors may review those
                                        particular campaigns.
                                        <br />
                                        <br />
                                        To meet legal requirements, including complying with court
                                        orders, valid discovery requests, valid subpoenas, and other
                                        appropriate legal mechanisms.
                                        <br />
                                        <br />
                                        To provide information to representatives and advisors,
                                        including attorneys and accountants, to help us comply with
                                        legal, accounting, or security requirements.
                                        <br />
                                        <br />
                                        To prosecute and defend a court, arbitration, or similar legal
                                        proceeding.
                                        <br />
                                        <br />
                                        To respond to lawful requests by public authorities, including
                                        to meet national security or law enforcement requirements.
                                        <br />
                                        <br />
                                        Combined information. We may combine Personal Information with
                                        other information we collect or obtain about you (such as
                                        information we source from our third-party partners) to serve
                                        you specifically, such as to deliver a product or service
                                        according to your preferences or restrictions, or for
                                        advertising or targeting purposes in accordance with this
                                        privacy policy. When we combine Personal Information with
                                        other information in this way, we treat it as, and apply all
                                        of the safeguards in this privacy policy applicable to,
                                        Personal Information.
                                        <br />
                                        <br />
                                        Other purposes. To carry out other legitimate business
                                        purposes, as well as other lawful purposes about which we will
                                        notify you.
                                        <br />
                                        <br />
                                        C. Cookies and Tracking Technologies
                                        <br />
                                        <br />
                                        We and our partners may use various technologies to collect
                                        and store information when you use our Services, and this may
                                        include using cookies and similar tracking technologies, such
                                        as pixels and web beacons. For example, we use web beacons in
                                        the emails we send. These web beacons track certain behavior
                                        such as whether the email sent through the Services was
                                        delivered and opened and whether links within the email were
                                        clicked. They also allow us to collect information such as the
                                        recipient’s IP address, browser, email client type and other
                                        similar details. We use this information to measure the
                                        performance of your emails, and to provide analytics
                                        information and enhance the effectiveness of our Services.
                                        Reports are also available to us when we send email to you, so
                                        we may collect and review that information.
                                        <br />
                                        <br />
                                        Other Web Site Analytics Services. Subject to your opt-out
                                        preferences (see E. Other Data Protection Rights), we use
                                        third party service providers such as Google Analytics to
                                        provide certain analytics and user interactions services to
                                        Ingomu Learning in connection with our operation of our
                                        Platform, including the collection and tracking of certain
                                        data and information regarding the characteristics and
                                        activities of visitors to our Sites. You may opt-out of Google
                                        Analytics’ cookies using Opt-Out Features on their website.
                                        <br />
                                        <br />
                                        D. With Whom We May Share Your Information
                                        <br />
                                        <br />
                                        We do not share your personal information with others except
                                        as indicated within this Policy or when we inform you and give
                                        you an opportunity to opt out of having your personal
                                        information shared.
                                        <br />
                                        <br />
                                        We will share information we collect about you, including
                                        personal information, in the following ways:
                                        <br />
                                        <br />
                                        With third party service providers, coaches, agents, or
                                        contractors. We use other companies, coaches, agents or
                                        contractors ("Service Providers") to perform services on our
                                        behalf or to assist us with providing services to you. For
                                        example, we may engage Service Providers to process credit
                                        card transactions or other payment methods. Or, we may engage
                                        Service Providers to provide services such as marketing,
                                        advertising, communications, infrastructure and IT services,
                                        to provide customer service, to collect debts, and to analyze
                                        and enhance data (including data about users' interactions
                                        with our service). These Service Providers may have access to
                                        your personal or other information in order to provide these
                                        functions. In addition, some of the information we request may
                                        be collected by third party providers on our behalf. We
                                        require our Service Providers to agree to take reasonable
                                        steps to keep the personal information that we provide to them
                                        secure. We do not authorize them to use or disclose your
                                        personal information except in connection with providing their
                                        services.
                                        <br />
                                        <br />
                                        Affiliates. We may disclose your information to current or
                                        future affiliates or subsidiaries for research, marketing, and
                                        other purposes consistent with this Privacy Policy.
                                        <br />
                                        <br />
                                        To comply with legal process or to protect Ingomu Learning and
                                        our users and members. We may share your data: if we believe
                                        that disclosure is reasonably necessary to comply with a law,
                                        regulation, legal or governmental request; to respond to a
                                        subpoena, court order, warrant, or other legal process; to
                                        enforce applicable terms of use or this Policy, including
                                        investigation of potential violations thereof; to protect the
                                        safety, rights, or property of the public, any person, or
                                        Ingomu Learning; to detect, prevent, or otherwise address,
                                        security, or technical issues or illegal or suspected illegal
                                        activities (including fraud); or as evidence in litigation in
                                        which we are involved, as part of a judicial or regulatory
                                        proceeding.
                                        <br />
                                        <br />
                                        Business Transfers. We may engage in a merger, acquisition,
                                        bankruptcy, dissolution, reorganization, or similar
                                        transaction or proceeding that involves the transfer of the
                                        information described in this Policy. In such transactions,
                                        customer information is typically one of the business assets
                                        that is transferred or acquired by a third party. If we are
                                        acquired by or merged with another company, if we sell or
                                        transfer a business unit or assets to another company, in the
                                        unlikely event of a bankruptcy proceeding, or as part of any
                                        other similar business transfer, you acknowledge that such
                                        transfers may occur.
                                        <br />
                                        <br />
                                        Aggregate or De-identified Information. We may disclose
                                        aggregate, anonymous, or de-identified information about users
                                        for marketing, advertising, research, compliance, or other
                                        purposes.
                                        <br />
                                        <br />
                                        E. Other Data Protection Rights
                                        <br />
                                        <br />
                                        You and your Contacts may have the following data protection
                                        rights:
                                        <br />
                                        <br />
                                        To access, correct, update or request deletion of Personal
                                        Information. Ingomu Learning takes reasonable steps to ensure
                                        that the data we collect is reliable for its intended use,
                                        accurate, complete and up to date.
                                        <br />
                                        <br />
                                        If Personal Information is collected or processed on the basis
                                        of consent, the data subject can withdraw their consent at any
                                        time. Withdrawing your consent will not affect the lawfulness
                                        of any processing we conducted prior to your withdrawal, nor
                                        will it affect processing of your Personal Information
                                        conducted in reliance on lawful processing grounds other than
                                        consent.
                                        <br />
                                        <br />
                                        If you do not wish to receive promotional emails from us, you
                                        may opt out at any time by following the opt-out link
                                        contained in the email itself. Generally immediate, please
                                        note that it may take up to ten (10) days to process your
                                        request. Please also note that if you opt out of receiving
                                        marketing communications from us, we may continue to send to
                                        you service-related emails which are not available for
                                        opt-out. If you do not wish to receive any service-related
                                        emails from us, you have the option to deactivate your
                                        account.
                                        <br />
                                        <br />
                                        You may also refrain from providing, or withdraw, your consent
                                        for cookies. Your browser’s help function should contain
                                        instructions on how to set your computer to accept all
                                        cookies, to notify you when a cookie is issued, or to not
                                        receive cookies at any time.
                                        <br />
                                        <br />
                                        Third Party Analytics Services. Some of the services used
                                        provide the ability to opt-out. You may opt-out of Google
                                        Analytics’ using Opt-Out Features on their respective
                                        websites.
                                        <br />
                                        <br />
                                        The Google Analytics service is provided by Google Inc. You
                                        can opt-out from Google Analytics service from using your
                                        information by installing the Google Analytics Opt-out Browser
                                        tool: tools.google.com/dlpage/gaoptout. For more information
                                        on the privacy practices of Google, please visit the Google
                                        Privacy & Terms web page: www.google.com/policies/privacy.
                                        <br />
                                        <br />
                                        Additional Rights. Subject to local law, you may have
                                        additional rights under the laws of your jurisdiction
                                        regarding your personal data, such as the right to complain to
                                        your local data protection authority.
                                        <br />
                                        <br />
                                        The right to complain to a data protection authority about the
                                        collection and use of Personal Information. For more
                                        information, please contact your local data protection
                                        authority. Contact details for data protection authorities in
                                        the EEA are available <a
                                            href="https://ec.europa.eu/justice/article-29/structure/data-protection-authorities/index_en.htm"
                                            target="_blank">here</a>.
                                        <br />
                                        <br />
                                        We respond to all requests we receive from individuals wishing
                                        to exercise their data protection rights in accordance with
                                        applicable data protection law within 24 hours of receiving
                                        the request during regular business hours, M-F 8:00 a.m. to
                                        5:00 p.m. MST. We may ask you to verify your identity in order
                                        to help us respond efficiently to your request.
                                        <br />
                                        <br />
                                        F. Legal Basis for Processing
                                        <br />
                                        <br />
                                        We process Personal Information about you as a data controller
                                        as described in this section, where such processing is in our
                                        legitimate interests and not overridden by your data
                                        protection interests or fundamental rights and freedoms. Our
                                        legitimate interests typically include: improving,
                                        maintaining, providing, and enhancing our technology, products
                                        and services; ensuring the security of the Services and our
                                        Website; and for our marketing activities.
                                        <br />
                                        <br />
                                        3. PRIVACY FOR VISITORS
                                        <br />
                                        <br />
                                        This section applies to Personal Information that we collect
                                        and process through our Ingomu Services and in the usual
                                        course of our business, such as in connection with our
                                        recruitment, events, sales and marketing activities. In this
                                        section "you" and "your" refers to Visitors.
                                        <br />
                                        <br />
                                        A. Information We Collect
                                        <br />
                                        <br />
                                        (i) Information you provide to us on the Websites or
                                        otherwise: Certain parts of our websites may ask you to
                                        provide Personal Information voluntarily. For example, we may
                                        ask you to provide certain Personal Information (such as your
                                        name, contact details, company name, profile information) to
                                        subscribe to a newsletter, apply to become a Coach, or
                                        otherwise submit inquiries to us. We may also collect Personal
                                        Information, such as your contact and job details and
                                        feedback, when you attend our events, take part in surveys, or
                                        through other business or marketing interactions we may have
                                        with you. You may choose to provide additional information
                                        when you communicate with us or otherwise interact with us,
                                        and we will keep copies of any such communications for our
                                        records. The Personal Information that you are asked to
                                        provide, and the reasons why you are asked to provide it, will
                                        be made clear to you at the point we ask you to provide your
                                        Personal Information. We will also let you know prior to
                                        collection whether the provision of the Personal Information
                                        we are collecting is compulsory or may be provided on a
                                        voluntary basis and the consequences, if any, of not providing
                                        the information.
                                        <br />
                                        <br />
                                        (ii) Information we collect automatically through the
                                        Websites: At this time, we do not collect any information
                                        automatically.
                                        <br />
                                        <br />
                                        4. SECURITY AND STORAGE
                                        <br />
                                        <br />
                                        We have taken reasonable steps to help protect the personal
                                        information we collect. These measures include using SSL
                                        encryption. Unfortunately, no measures can be guaranteed to
                                        provide 100% security. Accordingly, we cannot guarantee the
                                        security of your information in all circumstances.
                                        <br />
                                        <br />
                                        You should take steps to protect against unauthorized access
                                        to your device and account by, among other things, choosing a
                                        robust password that nobody else knows or can easily guess and
                                        keeping your log-in and password private. We are not
                                        responsible for any lost, stolen, or compromised passwords or
                                        for any activity on your account via unauthorized password
                                        activity.
                                        <br />
                                        <br />
                                        We retain the personal data we collect for so long as
                                        reasonably necessary to fulfill the purposes for which the
                                        data was collected, to perform our contractual and legal
                                        obligations, and for any applicable statute of limitations
                                        periods for the purposes of bringing and defending claims.
                                        <br />
                                        <br />
                                        5. THIRD PARTY LINKS
                                        <br />
                                        <br />
                                        Our Platform may contain links to third-party websites and
                                        applications. Subject to your opt-out preferences (see Other
                                        Data Protection Rights). Any access to and use of such linked
                                        websites and applications is not governed by this Policy but
                                        instead is governed by the privacy policies of those third
                                        parties. We do not endorse these parties, their content, or
                                        any products and services they offer, and we do not endorse
                                        these parties, their content, or any products and services
                                        they offer, and we are not responsible for the information
                                        practices of such third-party websites or applications.
                                        <br />
                                        <br />
                                        6. USERS OUTSIDE THE USA
                                        <br />
                                        <br />
                                        Our application and database servers are located with Amazon
                                        Cloud Services and Google in the United States. Amazon Cloud
                                        Services has certified with the Department of Commerce that it
                                        adheres to the Privacy Shield Principles under the EU-U.S. and
                                        Swiss-U.S. Privacy Shield frameworks. If you are an individual
                                        located in the European Economic Area, the United Kingdom,
                                        Canada or another jurisdiction outside of the United States
                                        with laws and regulations governing personal data collection,
                                        use, and disclosure that differ from United States laws,
                                        please be aware that information we collect (including through
                                        the use of methods such as cookies and other web technologies)
                                        will be processed and stored in the United States or in other
                                        countries where we or our third-party services providers have
                                        operations. By submitting your personal information to Ingomu
                                        Learning and using Ingomu Learning, you expressly consent to
                                        having your personal data transferred to, processed, and
                                        stored in the United States or another jurisdiction which may
                                        not offer the same level of privacy protection as those in the
                                        country where you reside or are a citizen.
                                        <br />
                                        <br />
                                        7. CALIFORNIA DISCLOSURES
                                        <br />
                                        <br />
                                        California Information-Sharing Disclosure. California
                                        residents may request a list of all third parties with respect
                                        to which we have disclosed any information about you for
                                        direct marketing purposes and the categories of information
                                        disclosed. If you are a California resident and want such a
                                        list, please send us a written request by email to
                                        <a href="mailto:Privacy@Ingomu.com">Privacy@Ingomu.com</a>
                                        with “California Privacy Rights” in the subject line.
                                        <br />
                                        <br />
                                        Do Not Track. We do not currently recognize or respond to
                                        browser-initiated Do Not Track signals as there is no
                                        consistent industry standard for compliance.
                                        <br />
                                        <br />
                                        8. CHANGES TO THE POLICY
                                        <br />
                                        <br />
                                        This Policy is current as of the Effective Date set forth
                                        above. We may change this Policy from time to time, and if we
                                        do, we’ll post any changes, including any material changes, on
                                        this page, so please be sure to check back periodically. If
                                        you continue to use Ingomu Learning after those changes are in
                                        effect, you agree to the revised Policy.
                                        <br />
                                        <br />
                                        9. CONTACTING US
                                        <br />
                                        <br />
                                        If you have questions or comments about this policy, please
                                        contact us at:
                                        <br />
                                        Ingomu Learning LLC
                                        <br />
                                        1550 Larimer Street, Ste. 465
                                        <br />
                                        Denver, CO 80202
                                        <br />
                                        United States
                                    </div>
                                    <div id="opt5">
                                        <span class="title-font" style="color: #3b5998">GDPR PLEDGE</span>
                                        <a href="#top" style="float: right"><span class="title-font">Back to
                                                Top</span></a><br />
                                        Last Updated, September 14, 2021
                                        <br />
                                        <br />
                                        At Ingomu, we are committed to providing our customers with
                                        transparency regarding our privacy practices and compliance
                                        with European Union (EU) privacy regulations. We value your
                                        trust and are dedicated to protecting your privacy.
                                        <br />
                                        <br />
                                        In May 2018, a new data privacy law known as the EU General
                                        Data Protection Regulation (or the "GDPR") becomes effective.
                                        The GDPR requires Ingomu Learning, Coaches and Users using the
                                        Service to provide Users with more information about the
                                        processing of their Personal Data.
                                        <br />
                                        <br />
                                        Here is what you need to know:
                                        <br />
                                        <br />
                                        Legal grounds for processing your Personal Data:
                                        <br />
                                        <br />
                                        The GDPR requires us to tell you about the legal ground we're
                                        relying on to process any Personal Data about you. The legal
                                        grounds for us processing your Personal Data for the purposes
                                        set out in Legal Basis for Processing in our Privacy Policy
                                        will typically be because:
                                        <br />
                                        • you provided your consent;
                                        <br />
                                        • it is necessary for our contractual relationship;
                                        <br />
                                        • the processing is necessary for us to comply with our legal
                                        or regulatory obligations; and/or
                                        <br />
                                        • the processing is in our legitimate interest as a coaching
                                        platform (for example, to protect the security and integrity
                                        of our systems and to provide you with customer service,
                                        etc.).
                                        <br />
                                        <br />
                                        1. TRANSFERS OF PERSONAL DATA
                                        <br />
                                        <br />
                                        As Ingomu Learning is a global company, we may need to
                                        transfer your Personal Data outside of the country from which
                                        it was originally provided. This may be intra-group or to
                                        third parties that we work with who may be located in
                                        jurisdictions outside the EEA, Switzerland and the UK which
                                        have no data protection laws or laws that are less strict
                                        compared with those in Europe.
                                        <br />
                                        <br />
                                        Whenever we transfer Personal Data outside of the EEA,
                                        Switzerland or the UK, we take legally required steps to make
                                        sure that appropriate safeguards are in place to protect your
                                        Personal Data. Feel free to contact us as set forth in Section
                                        5 for more information about the safeguards we have put in
                                        place to protect your Personal Data and privacy rights in
                                        these circumstances.
                                        <br />
                                        <br />
                                        2. PERSONAL DATA RETENTION
                                        <br />
                                        <br />
                                        We retain your Personal Data for as long as necessary to
                                        provide you with our Services, or for other important purposes
                                        such as complying with legal obligations, resolving disputes,
                                        and enforcing our agreements.
                                        <br />
                                        <br />
                                        If you have an account with us, we will retain your Personal
                                        Data, unless you request your data to be deleted. Upon
                                        execution of your request, all your Personal Data will be
                                        deleted, unless such request conflicts with our contractual
                                        obligation to you, or the requirement for us to comply with
                                        our legal and regulatory obligations. We will make you aware
                                        of any data that may need to be kept and for how long upon
                                        your request.
                                        <br />
                                        <br />
                                        3. YOUR RIGHTS
                                        <br />
                                        <br />
                                        Data protection law provides you with rights in respect of
                                        Personal Data that we hold about you, including the right to
                                        request a copy of the Personal Data, request that we rectify,
                                        restrict or delete your Personal Data, object to profiling and
                                        unsubscribe from marketing communications.
                                        <br />
                                        <br />
                                        For the most part, you can you can exercise these rights by
                                        logging in and visiting your Dashboard page or changing the
                                        "cookie settings" in your browser (see our Cookie Statement
                                        for more information). If you can't find what you're looking
                                        for in your Dashboard, please contact us using the contact
                                        information set out in Section 5 below. Please note that
                                        requests to exercise data protection rights will be assessed
                                        by us on a case-by-case basis. There may be circumstances
                                        where we are not legally required to comply with your request
                                        because of the laws in your jurisdiction or because of
                                        exemptions provided for in data protection legislation.
                                        <br />
                                        <br />
                                        If you have a complaint about how we handle your Personal
                                        Data, please get in touch with us as set forth in Section 5 to
                                        explain. If you are not happy with how we have attempted to
                                        resolve your complaint, you may contact the relevant data
                                        protection authority.
                                        <br />
                                        <br />
                                        4. INGOMU LEARNING AS A DATA CONTROLLER AND DATA PROCESSOR
                                        <br />
                                        <br />
                                        EU data protection law makes a distinction between
                                        organizations that process Personal Data for their own
                                        purposes (known as "data controllers") and organizations that
                                        process personal data on behalf of other organizations (known
                                        as "data processors"). If you have a question or complaint
                                        about how your Personal Data is handled, these should always
                                        be directed to the relevant data controller since they are the
                                        ones with primary responsibility for your Personal Data.
                                        <br />
                                        <br />
                                        Ingomu Learning acts as a data controller in respect of your
                                        Personal Data.
                                        <br />
                                        <br />
                                        For example, if you create an account with us, Ingomu Learning
                                        will be a data controller in respect of the Personal Data that
                                        you provide as part of your account. We will also be a data
                                        controller of the Personal Data that we have obtained about
                                        the use of the Applications, which could relate to Coaches or
                                        Users. We use this to conduct research and analysis to help
                                        better understand and serve Users of the Services as well as
                                        to improve our platform and provide you with more targeted
                                        recommendations about events we think may be of interest to
                                        you.
                                        <br />
                                        <br />
                                        5. CONTACTING US
                                        <br />
                                        <br />
                                        If you have questions or comments about this policy, want to
                                        request an update to your Personal Information or have your
                                        Personal Information removed, please contact us:
                                        <br />
                                        <br />
                                        Ingomu Learning LLC
                                        <br />
                                        1550 Larimer Street, Ste. 465
                                        <br />
                                        Denver, CO 80202
                                        <br />
                                        United States
                                        <br />
                                        <a href="mailto:Privacy@Ingomu.com">Privacy@Ingomu.com</a>
                                    </div>
                                    <div id="opt6">
                                        <span class="title-font" style="color: #3b5998">SUBPROCESSORS</span>
                                        <a href="#top" style="float: right"><span class="title-font">Back to
                                                Top</span></a><br />
                                        Last Updated, September 14, 2021
                                        <br />
                                        <br />
                                        Ingomu Learning LLC uses certain subprocessors to support the
                                        delivery of our Ingomu Services. This page provides important
                                        information about the identity, location and role of each
                                        subprocessor.
                                        <br />
                                        <br />
                                        WHAT IS A SUBPROCESSOR?
                                        <br />
                                        <br />
                                        A Subprocessor is a third-party data processor engaged by
                                        Ingomu Learning, who has or potentially will have access to,
                                        or process customer data. Prior to engaging with any
                                        third-party processor, Ingomu Learning evaluates their
                                        security and privacy policies.
                                        <br />
                                        <br />
                                        INGOMU SUBPROCESSORS
                                        <br />
                                        <br />
                                        Amazon Web Services (AWS) – Cloud Service Provider - Located
                                        in the United States
                                        <br />
                                        Google - Cloud Service Provider – Located in the United States
                                        <br />
                                        Mailchimp - Cloud-based transactional email service – Located
                                        in the United States
                                        <br />
                                        Mixpanel – Cloud Analytics – Located in the United States
                                        <br />
                                        Qonversion – Cloud Subscription Infrastructure – Located in
                                        the United States
                                        <br />
                                        <br />
                                        UPDATES
                                        <br />
                                        <br />
                                        As our business grows and evolves, the Subprocessors we
                                        utilize may also change. We will provide you with updates here
                                        of any new Subprocessors engaged by Ingomu Learning LLC.
                                    </div>
                                </div> <!-- /.tab-content -->

                            </div>
                            <!-- Terms End -->

                        </div>
                        <!-- Terms Content End -->
                    </div>
                </div>
            </div>
        </div>
        <!-- Page Single Post End -->

        <!-- Footer Start -->
        <SiteFooter></SiteFooter>
        <!-- Footer End -->
    </div>
</template>
<script>
import SiteHeader from "@/components/InfineHeader";
import SiteFooter from "@/components/InfineFooter";
import $ from "jquery";
import { slicknav } from '../mixins/slicknav';
import { parrallaxie } from "../mixins/parallaxie";
import { mapActions, mapGetters } from "vuex";
import { reveal } from "../mixins/reveal";

export default {
    name: "termsv4",
    metaInfo() {
		return {
			title: "Terms & Privacy | Ingomu App",
		}
	},
    components: {
        SiteHeader,
        SiteFooter
    },
    mounted() {
        if (this.$route.hash) {
            setTimeout(() => this.scrollTo(this.$route.hash), TIMEOUT);
        }
        var $window = $(window);

        $(function () {
            /* Preloader Effect */
            setTimeout(function () {
                $(".preloader").fadeOut(600);
            }, 600);

            /* Animated Wow Js */
            new WOW().init();

            // Parallax
            parrallaxie()

            // Reveal
            reveal()

            /* Slick Menu JS */
            slicknav()
        })
    },
}
</script>
<style scoped>
.page-header {
    background: url(https://ingomulibrary.s3.us-east-1.amazonaws.com/images/ingomu-terms-and-privacy.png);
}

@media (max-width:480px) {
    .page-header {
        background: url(https://ingomulibrary.s3.us-east-1.amazonaws.com/images/ingomu-terms-and-privacy-mobile.png);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
    }
}
</style>