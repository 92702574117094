<template>
    <div id="contact">
        <!-- Preloader Start -->
        <div class="preloader">
            <div class="loading-container">
                <div class="loading"></div>
                <div id="loading-icon"><img
                        src="https://ingomulibrary.s3.us-east-1.amazonaws.com/images/Ingomu-Logo-White.png" alt="">
                </div>
            </div>
        </div>
        <!-- Preloader End -->

        <!-- Header Start -->
        <SiteHeader></SiteHeader>
        <!-- Header End -->

        <!-- Page Header Start -->
        <div class="page-header bg-section parallaxie">
            <!-- Page Header Box Start -->
            <div class="page-header-box">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-lg-12">
                            <!-- Page Header Content Start -->
                            <div class="page-header-content">
                                <h1 class="wow fadeInUp">Contact <span>Us</span></h1>
                                <nav class="wow fadeInUp" data-wow-delay="0.25s">
                                    <ol class="breadcrumb">
                                        <li class="breadcrumb-item"><router-link
                                                :to="{ name: 'home' }">home</router-link></li>
                                        <li class="breadcrumb-item active" aria-current="page">Contact Us</li>
                                    </ol>
                                </nav>
                            </div>
                            <!-- Page Header Content End -->
                        </div>
                    </div>
                </div>
            </div>
            <!-- Page Header Box End -->
        </div>
        <!-- Page Header End -->

        <!-- Page Contact Us Start -->
        <div class="page-contact-us">
            <div class="container">
                <div class="row section-row align-items-center">
                    <div class="col-lg-7 col-md-9">
                        <!-- Section Title Start -->
                        <div class="section-title">
                            <h3 class="wow fadeInUp">Contact Us</h3>
                            <h2 class="wow fadeInUp" data-wow-delay="0.25s">Let's Connect<br><span>and Grow</span></h2>
                        </div>
                        <!-- Section Title End -->
                    </div>
                </div>

                <div class="row align-items-center">
                    <div class="col-lg-6">
                        <!-- Contact Us Form Start -->
                        <div class="contact-us-form">
                            <!-- Contact Us Title Start -->
                            <div class="contact-us-title">
                                <h3 class="wow fadeInUp textnone">Send a Message</h3>
                                <p class="wow fadeInUp" data-wow-delay="0.2s">
                                    By submitting this form, you consent to be contacted at the email address provided.
                                    You may opt out of communications at any time by following the instructions included
                                    in our emails. Your information will be kept strictly confidential and will not be
                                    shared, sold, or used for purposes other than those stated. For more details, please
                                    refer to our <router-link :to="{ name: 'terms' }">Privacy Policy</router-link>.
                                </p>
                            </div>
                            <!-- Contact Us Title End -->

                            <!-- Contact Us Form Start -->
                            <form id="contactForm" action="#" method="POST" data-toggle="validator" class="wow fadeInUp"
                                data-wow-delay="0.4s">
                                <div class="row">
                                    <div class="form-group col-md-6 mb-4">
                                        <input type="text" name="fname" class="form-control" id="fname"
                                            placeholder="First Name" required>
                                        <div class="help-block with-errors"></div>
                                    </div>

                                    <div class="form-group col-md-6 mb-4">
                                        <input type="text" name="lname" class="form-control" id="lname"
                                            placeholder="Last Name" required>
                                        <div class="help-block with-errors"></div>
                                    </div>

                                    <div class="form-group col-md-6 mb-4">
                                        <input type="email" name="email" class="form-control" id="email"
                                            placeholder="Email Address" required>
                                        <div class="help-block with-errors"></div>
                                    </div>

                                    <div class="form-group col-md-6 mb-4">
                                        <input type="text" name="phone" class="form-control" id="phone"
                                            placeholder="Phone Number" required>
                                        <div class="help-block with-errors"></div>
                                    </div>

                                    <div class="form-group col-md-12 mb-5">
                                        <textarea name="message" class="form-control" id="message" rows="4"
                                            placeholder="Message" required></textarea>
                                        <div class="help-block with-errors"></div>
                                    </div>

                                    <div class="col-lg-12">
                                        <div class="contact-form-btn">
                                            <button type="submit" class="btn-default mb-25">submit message</button>
                                            <div id="msgSubmit" class="h3 hidden"></div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                            <!-- Contact Us Form End -->
                        </div>
                        <!-- Contact Us Form End -->
                    </div>

                    <div class="col-lg-6">
                        <!-- Contact Information Start -->
                        <div class="contact-information">
                            <!-- Contact Info Item Start -->
                            <div class="contact-info-item wow fadeInUp">

                                <!-- Footer Contact Information Start -->
                                <div class="contact-info-contant">
                                <h3>Connect With Us</h3>
                                </div>
                                <!-- Footer Contact Information End -->

                                <!-- Footer Social Link Start -->
                                <!-- Post Social Links Start -->
                                <div class="post-social-sharing wow fadeInUp" data-wow-delay="0.5s">
                                    <ul>
                                        <li><a href="https://www.linkedin.com/company/ingomu" target="_blank"><i
                                                    class="fa-brands fa-linkedin-in"></i></a></li>
                                        <li><a href="https://www.instagram.com/ingomu.coaching/" target="_blank"><i
                                                    class="fa-brands fa-instagram"></i></a></li>
                                        <li><a href="https://www.youtube.com/@ingomu" target="_blank"><i
                                                    class="fa-brands fa-youtube"></i></a></li>
                                        <li><a href="https://www.facebook.com/ingomu.coaching/" target="_blank"><i
                                                    class="fa-brands fa-facebook-f"></i></a></li>
                                        <li><a href="https://www.pinterest.com/ingomu_coaching/"><i
                                                    class="fa-brands fa-pinterest"></i></a></li>
                                    </ul>
                                </div>
                                <!-- Post Social Links End -->
                                <!-- Footer Social Link End -->
                            </div>
                            <!-- Contact Info Item End -->


                            <!-- Contact Info Item Start -->
                            <div class="contact-info-item wow fadeInUp" data-wow-delay="0.25s">
                                <!-- Contact Info Contact Start -->
                                <div class="contact-info-contant">
                                    <h3>Get The App</h3>
                                </div>
                                <!-- Contact Info Contact End -->

                                <!-- Contact Info Body Start -->
                                <div class="contact-info-body">
                                    <div class="col-lg-12 row">
                                        <div class="col-lg-12 col-12 mb-3">
                                            <a href="https://apps.apple.com/us/app/ingomu/id1621410470" target="_blank">
                                                <img src="https://ingomulibrary.s3.us-east-1.amazonaws.com/images/app_badge.svg"
                                                    alt="" class="h-50 mr-20">
                                            </a>
                                            <a href="https://play.google.com/store/apps/details?id=com.ingomu.ingomu"
                                                target="_blank">
                                                <img src="https://ingomulibrary.s3.us-east-1.amazonaws.com/images/google_badge.png"
                                                    alt="" class="h-50">
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <!-- Contact Info Body End -->
                            </div>
                            <!-- Contact Info Item End -->

                             <!-- Contact Info Item Start -->
                             <div class="contact-info-item wow fadeInUp" data-wow-delay="0.25s">
                                <!-- Contact Info Contact Start -->
                                <div class="contact-info-contant">
                                    <a href="https://calendar.app.google/DxVRDNezM5idW1p1A" target="_blank"
                                    class="btn-default textnone">Book a Call Today</a>
                                </div>
                                <!-- Contact Info Contact End -->

                            </div>
                            <!-- Contact Info Item End -->
                             
                        </div>
                        <!-- Contact Information End -->
                    </div>
                </div>
            </div>
        </div>
        <!-- Page Contact Us End -->


        <!-- Footer Start -->
        <SiteFooter></SiteFooter>
        <!-- Footer End -->
    </div>
</template>
<script>
import SiteHeader from "@/components/InfineHeader";
import SiteFooter from "@/components/InfineFooter";
import $ from "jquery";
import { slicknav } from '../../../mixins/slicknav';
import { parrallaxie } from "../../../mixins/parallaxie";
import { mapActions, mapGetters } from "vuex";
import { reveal } from "../../../mixins/reveal";
import { validator } from "../../../mixins/validator";

export default {
    name: "contactv4",
    metaInfo() {
		return {
			title: "Contact Us | Ingomu App",
		}
	},
    components: {
        SiteHeader,
        SiteFooter
    },
    methods: {
        ...mapActions(["contactUs"]),
        submit(name, email, phone, message) {
            var _this = this;
            _this.contactUs({
                name: name,
                email: email,
                phone: phone,
                message: message,
                page: "Contact"
            })
        }
    },
    mounted() {
        if (this.$route.hash) {
            setTimeout(() => this.scrollTo(this.$route.hash), TIMEOUT);
        }

        var $window = $(window);
        var $this = this;

        $(function () {
            /* Preloader Effect */
            setTimeout(function () {
                $(".preloader").fadeOut(600);
            }, 600);

            /* Animated Wow Js */
            new WOW().init();

            // Parallax
            parrallaxie()

            // Reveal
            reveal()

            /* Slick Menu JS */
            slicknav()

            validator((contactForm) => {
                let name = contactForm[0]["value"] + " " + contactForm[1]["value"];
                let email = contactForm[2]["value"];
                let phone = contactForm[3]["value"];
                let message = contactForm[4]["value"];
                $this.submit(name, email, phone, message);
            })

        })
    }
}
</script>
<style scoped>
.page-header {
    background: url(https://ingomulibrary.s3.us-east-1.amazonaws.com/images/ingomu-contact-us.png);
}

.mb-25 {
    margin-bottom: 25px;
}

.post-social-sharing {
    text-align: left;
    margin-top: 30px;
}

.h-50 {
    height: 50px !important;
}

.mr-20 {
    margin-right: 20px;
}

.textnone {
    text-transform: none;
}

@media (max-width:480px) {
    .page-header {
        background: url(https://ingomulibrary.s3.us-east-1.amazonaws.com/images/ingomu-contact-us-mobile.png);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
    }
}
</style>